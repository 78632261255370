import React from 'react'
import {
  Datagrid,
  FilterButton,
  FunctionField,
  List,
  type ListProps,
  Pagination,
  SearchInput,
  SelectInput,
  ShowButton,
  TextField,
  TopToolbar
} from 'react-admin'
import { Chip } from '@mui/material'

import type { CscDevice } from '../types'

const CscDeviceFilters = () => {
  return [
    <SearchInput fullWidth source="s" alwaysOn placeholder="Fabricant ou Nom" />,
    <SelectInput
      alwaysOn
      source="batteryLevel"
      choices={[
        { id: '5', name: 'Moins de 5%' },
        { id: '25', name: 'Moins de 25%' },
        { id: '50', name: 'Moins de 50%' },
        { id: '75', name: 'Moins de 75%' }
      ]}
    />
  ]
}

const CscDeviceListActions = (props: any) => (
  <TopToolbar>
    <FilterButton />
  </TopToolbar>
)

const CscDeviceList = (props: ListProps) => (
  <List
    {...props}
    filters={CscDeviceFilters()}
    perPage={20}
    pagination={<Pagination rowsPerPageOptions={[20, 50, 100]} />}
    actions={<CscDeviceListActions />}>
    <Datagrid bulkActionButtons={false}>
      <TextField source="id" />
      <TextField source="manufacturerName" />
      <TextField source="name" />
      <FunctionField
        sortable
        label="resources.csc-device.fields.batteryLevel"
        render={(record: CscDevice) => {
          if (record.name?.startsWith('QNY')) {
            return <Chip label={'N/A'} />
          } else {
            let label = record.batteryLevel ? `${record.batteryLevel} %` : 'N/A'
            if (record.batteryLevel && record.batteryLevel > 0 && record.batteryLevel <= 5) {
              return <Chip label={label} color="error" />
            } else if (record.batteryLevel && record.batteryLevel > 5 && record.batteryLevel <= 30) {
              return <Chip label={label} color="warning" />
            } else {
              return <Chip label={label} />
            }
          }
        }}
      />
      <ShowButton label="Voir" />
    </Datagrid>
  </List>
)

export default CscDeviceList
