import React, { useState } from 'react'
import { useLogin } from 'react-admin'
import { useNavigate } from 'react-router-dom'
import { Avatar, Button, TextField } from '@mui/material'
import { Lock } from '@mui/icons-material'
import AuthLayout from './AuthLayout'

const LoginPageWithForgotPassword = ({ theme }: any) => {
  const navigate = useNavigate()
  const [username, setUsername] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const [loading, setLoading] = useState<boolean>(false)
  const login = useLogin()

  const submit = (e: any) => {
    e.preventDefault()
    setLoading(true)
    login({ username, password })
  }

  return (
    <AuthLayout>
      <form onSubmit={submit} style={{ display: 'flex', flexDirection: 'column', minWidth: 300 }}>
        <Avatar style={{ alignSelf: 'center', marginBottom: 16 }}>
          <Lock />
        </Avatar>
        <TextField
          style={{ marginBottom: 16 }}
          autoFocus
          name="email"
          type="email"
          value={username}
          label="Identifiant"
          error={username === ''}
          helperText={username === '' ? "L'identifiant est obligatoire" : ' '}
          required
          onChange={(e) => setUsername(e.target.value)}
        />
        <br />
        <TextField
          style={{ marginBottom: 16 }}
          name="password"
          type="password"
          value={password}
          label="Mot de passe"
          required
          error={!password}
          helperText={password === '' ? 'Le mot de passe est obligatoire' : ' '}
          onChange={(e) => setPassword(e.target.value)}
        />
        <Button variant="contained" color="primary" type="submit" disabled={loading || username === '' || password === ''}>
          Connexion
        </Button>
        <div style={{ height: 30 }} />
        <Button color="primary" onClick={() => navigate(`/forgot-password?redirect=${encodeURIComponent(username)}`)}>
          Mot de passe oublié ?
        </Button>
      </form>
    </AuthLayout>
  )
}

export default LoginPageWithForgotPassword
