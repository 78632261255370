import React from 'react'
import { TextInput, required, Title, TopToolbar, email, Edit, Form } from 'react-admin'
import { Paper } from '@mui/material'

const ProfileEdit = () => {
  return (
    <Edit>
      <Title title={`Mon profil`} />
      <TopToolbar />
      <Form>
        <Paper style={{ display: 'flex', flexDirection: 'column' }}>
          <TextInput source="pseudo" validate={required()} />
          <TextInput source="lastName" validate={required()} />
          <TextInput source="firstName" validate={required()} />
          <TextInput source="email" validate={[required(), email()]} />
        </Paper>
      </Form>
    </Edit>
  )
}

export default ProfileEdit
