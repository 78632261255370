import React, { createContext, type FC } from 'react'
import useProfile, { type UseProfileType, initialProfileContext } from './useProfile'

export const ProfileContext = createContext<UseProfileType>(initialProfileContext)

interface Props {
  children: React.ReactNode;
}

const ProfileProvider: FC<Props> = ({ children }) => {
  const profileContext = useProfile()
  return <ProfileContext.Provider value={profileContext}>{children}</ProfileContext.Provider>
}

export default ProfileProvider
