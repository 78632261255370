import { Fragment } from 'react'
import { useNavigate } from 'react-router-dom'
import { Title, TopToolbar, Button } from 'react-admin'
import { Grid } from '@mui/material'
import { ChevronLeft } from '@mui/icons-material'

import { StatDeviceOSType, StatDeviceOSVersion } from '../components/StatFields'

const DeviceStats = (props: any) => {
  const navigate = useNavigate()
  return (
    <Fragment>
      <Title title={`Téléphones : statistiques `} />
      <TopToolbar>
        <Button color="primary" startIcon={<ChevronLeft />} onClick={() => navigate(`/device`)} label="Retour aux Téléphones" />
        <div style={{ width: 1, flex: 1 }} />
      </TopToolbar>
      <Grid container>
        <Grid item xs={12} sm={6} md={4} pb={2}>
          <StatDeviceOSType />
        </Grid>
        <Grid item xs={12} sm={6} md={4} pb={2}>
          <StatDeviceOSVersion os="Android" />
        </Grid>
        <Grid item xs={12} sm={6} md={4} pb={2}>
          <StatDeviceOSVersion os="iOS" />
        </Grid>
      </Grid>
    </Fragment>
  )
}

export default DeviceStats
