import React, { useState } from 'react'
import {
  BooleanField,
  Button,
  NumberField,
  ReferenceField,
  Show,
  type ShowProps,
  SimpleShowLayout,
  TextField,
  TopToolbar,
  useRecordContext,
  useRefresh
} from 'react-admin'
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material'
import { Done } from '@mui/icons-material'

import { ContactSubjectField } from '../components/ContactFields'
import { ShortDateTimeField } from '../components/ShortDateTimeField'
import type { Contact } from '../types'
import Api from '../core/api'

const ContactShowActions = (props: any) => {
  const record = useRecordContext<Contact>(props)
  const refresh = useRefresh()
  const [showMarkAsProcessedDialog, setShowMarkAsProcessedDialog] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)

  const markAsProcessed = async () => {
    setLoading(true)
    await Api.post('contact/mark-as-processed', { id: record?.id, processed: true })
    setLoading(false)
    refresh()
    setShowMarkAsProcessedDialog(false)
  }
  return (
    <TopToolbar>
      <Button onClick={() => setShowMarkAsProcessedDialog(true)} disabled={record.processed} label={'resources.contact.actions.done'}>
        <Done />
      </Button>
      <Dialog
        open={showMarkAsProcessedDialog}
        onClose={() => setShowMarkAsProcessedDialog(false)}
        aria-labelledby="alert-dialog-block-title"
        aria-describedby="alert-dialog-block-description">
        <DialogTitle id="alert-dialog-block-title">Marquer comme traité ?</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-block-description">
            Avant de marquer ce message comme traité, assurez-vous d'avoir effectué les actions nécessaires (si besoin). Ce message sera
            retiré de la liste des messages à traiter envoyé chaque jour
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="primary" label="Marqué comme Traité" disabled={loading} onClick={markAsProcessed} />
        </DialogActions>
      </Dialog>
    </TopToolbar>
  )
}

const ContactShow = (props: ShowProps) => {
  return (
    <Show actions={<ContactShowActions />}>
      <SimpleShowLayout>
        <NumberField source="id" />
        <ContactSubjectField source="subject" />
        <TextField source="body" />
        <ReferenceField source="account.id" reference="account">
          <TextField source="pseudo" />
        </ReferenceField>
        <BooleanField source="processed" />
        <ShortDateTimeField source="creationDate" />
        <ShortDateTimeField source="updateDate" />
        <ReferenceField source="processedBy.id" reference="account">
          <TextField source="pseudo" />
        </ReferenceField>
      </SimpleShowLayout>
    </Show>
  )
}

export default ContactShow
