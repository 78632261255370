import Timer from '@mui/icons-material/Timer'
import SessionEdit from './edit'
import SessionList from './list'
import SessionShow from './show'

const exp = {
  edit: SessionEdit,
  icon: Timer,
  list: SessionList,
  show: SessionShow
}

export default exp
