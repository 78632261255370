import React, { useEffect, useState } from 'react'

const useProgressiveImage = (src: string) => {
  const [sourceLoaded, setSourceLoaded] = useState<string | null>(null)

  useEffect(() => {
    const img = new Image()
    img.src = src
    img.onload = () => setSourceLoaded(src)
  }, [src])

  return sourceLoaded
}

const LazyImage = (props: any) => {
  const loaded = useProgressiveImage(props.source)
  return (
    <div
      style={{
        backgroundImage: `url(${loaded || props.placeholder})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundAttachment: 'fixed',
        backgroundPosition: 'center center'
      }}
    >
      {props.children}
    </div>
  )
}

export default LazyImage
