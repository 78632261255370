import { DateField } from 'react-admin'

export const ShortDateTimeField = (props: any) => (
  <DateField {...props} // source={props.source}
    showTime
    options={{
      year: '2-digit', month: '2-digit', day: '2-digit', // sinon default est undefined et le composant ne s'affiche pas
      hour: '2-digit', minute: '2-digit', second: '2-digit' }} // psam 01/24, 'numeric' s'affiche aussi en 0n
  />
)

export const ShortDateField = (props: any) => (
  <DateField {...props} // source={props.source}
    options={{ year: '2-digit', month: '2-digit', day: '2-digit' }} />
)

/* psam 01/24, mis off car sans valeur ajoutée par rapport à <ShortDateTimeField> (avec éventuel emptyText=)
import { DateField as RADateField, useRecordContext } from 'react-admin'
import { Typography } from '@mui/material'
import { format } from 'date-fns'
import { utcToZonedTime } from 'date-fns-tz'

import type { Challenge } from '../types'
export const DateField = (props: any) => {
  const record = useRecordContext<Challenge>(props)
  if (record[props.source]) {
    return (
      <Typography variant="body2">
        {format(utcToZonedTime(new Date(record[props.source]), 'Europe/Paris'), 'dd/MM/yyyy HH:mm:ss')}
      </Typography>
    )
  } else {
    return <Typography variant="body2">(non renseigné)</Typography>
  }
}
*/
