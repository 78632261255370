import Notifications from '@mui/icons-material/Notifications'

import NotificationCreate from './create'
import NotificationList from './list'
import NotificationShow from './show'

const exp = {
  create: NotificationCreate,
  icon: Notifications,
  list: NotificationList,
  show: NotificationShow
}

export default exp
