import React from 'react'
import { UserMenu as RAUserMenu, useLogout, useTranslate } from 'react-admin'
// import { useNavigate } from 'react-router-dom'

import { MenuItem } from '@mui/material'
import { Logout } from '@mui/icons-material'

const UserMenu = (props: any) => {
  const translate = useTranslate()
  const logout = useLogout()
  // const navigate = useNavigate()
  // const goToProfile = () => navigate('profile')
  return (
    <RAUserMenu label={translate('resources.admin.menu')} {...props}>
      {
        // <MenuItem onClick={() => goToProfile()}>
        //   <Settings />
        //   &nbsp; Voir le profil
        // </MenuItem>
      }
      <MenuItem onClick={() => logout()}>
        <Logout />
        &nbsp; Déconnexion
      </MenuItem>
    </RAUserMenu>
  )
}

export default UserMenu
