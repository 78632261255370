import DirectionsBike from '@mui/icons-material/DirectionsBike'
import CscDeviceList from './list'
import CscDeviceShow from './show'

const exp = {
  icon: DirectionsBike,
  list: CscDeviceList,
  show: CscDeviceShow
}

export default exp
