import React from 'react'
import { Notification } from 'react-admin'
import { Card, CardContent, CardMedia, Grid } from '@mui/material'
import LazyImage from '../components/LazyImage'

const AuthLayout = (props: any) => {
  let source = './background-prod.jpg'
  return (
    <LazyImage source={source} placeholder="background">
      <Grid container spacing={0} direction="column" alignItems="center" style={{ minHeight: '100vh', paddingTop: '10vh' }}>
        <Grid item xs={12} sm={3}>
          <Card style={{ minWidth: 340 }}>
            <CardMedia style={{ width: 340, height: 130, margin: 'auto' }} image="./logo.png" title="Logo Queeny" />
            <CardContent>{props.children}</CardContent>
          </Card>
        </Grid>
      </Grid>
      <Notification />
    </LazyImage>
  )
}

export default AuthLayout
