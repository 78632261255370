import LocalActivity from '@mui/icons-material/LocalActivity'
import ChallengeCreate from './create'
import ChallengeEdit from './edit'
import ChallengeList from './list'
import ChallengeShow from './show'

const exp = {
  create: ChallengeCreate,
  edit: ChallengeEdit,
  icon: LocalActivity,
  list: ChallengeList,
  show: ChallengeShow
}

export default exp
