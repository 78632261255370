import { useState } from 'react'
import {
  BooleanField,
  Button,
  Datagrid,
  EditButton,
  FunctionField,
  Labeled,
  Link,
  Loading,
  NumberField,
  Pagination,
  type RaRecord,
  ReferenceManyField,
  Show,
  ShowButton,
  type ShowProps,
  Tab,
  TabbedShowLayout,
  TextField,
  TopToolbar,
  useCreatePath,
  useGetList,
  useNotify,
  useRecordContext,
  useRefresh,
  useTranslate
} from 'react-admin'
import type { SortPayload } from 'react-admin'
import {
  Box,
  Card,
  CardContent,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  TextField as MUITextField,
  type Theme,
  Typography
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import {
  AdminPanelSettings,
  ArrowDownward,
  ArrowUpward,
  Block,
  LockOpen,
  MonetizationOn,
  PriorityHigh,
  Send,
  Verified
} from '@mui/icons-material'

import AvatarImageField from '../components/AvatarImageField'
import { ChallengeOngoingField, ChallengeStatusField, ObjectiveField } from '../components/ChallengeFields'
import {
  SessionLinkChallengeField,
  SessionEndedByField,
  SessionScoreField,
  SessionRowStyle,
  SessionLinkDeviceField
} from '../components/SessionFields'
import { ShortDateField, ShortDateTimeField } from '../components/ShortDateTimeField'
import { type Account, Role } from '../types'
import Api from '../core/api'
import { OsField } from '../device/list'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex'
  },
  details: {
    display: 'flex',
    flexDirection: 'column'
  },
  content: {
    flex: '1 0 auto'
  },
  controls: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: 8,
    paddingBottom: 8
  },
  flexRow: {
    display: 'flex',
    flexDirection: 'row'
  }
}))

const Title = (props: any) => {
  const record = useRecordContext<Account>(props)
  if (record) {
    return (
      <span>
        Utilisateur n°{record.id} : {record.pseudo}
      </span>
    )
  } else {
    return <span>Chargement de l'utilisateur...</span>
  }
}

const AccountShowActions = (props: any) => {
  const refresh = useRefresh()
  const notify = useNotify()
  const translate = useTranslate()
  const record = useRecordContext<Account>(props)
  const [showBlockDialog, setShowBlockDialog] = useState<boolean>(false)
  const [showContactDialog, setShowContactDialog] = useState<boolean>(false)
  const [showPromoteDialog, setShowPromoteDialog] = useState<boolean>(false)
  const [showDowngradeDialog, setShowDowngradeDialog] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [subject, setSubject] = useState<string>('')
  const [body, setBody] = useState<string>('')

  /**
   *
   */
  const toggleLockUser = async () => {
    setLoading(true)
    await Api.post('account/block', {
      id: record?.id,
      isBlocked: !record?.isBlocked
    })
    setLoading(false)
    setShowBlockDialog(false)
    notify(record?.isBlocked ? `resources.account.helpers.unlocked` : `resources.account.helpers.locked`, {
      type: 'success',
      messageArgs: { smart_count: 1 }
    })
    refresh()
  }

  /**
   *
   */
  const contactUser = async () => {
    setLoading(true)
    const res = await Api.post('contact/admin', {
      id: record?.id,
      subject,
      body
    })
    setLoading(false)
    setShowContactDialog(false)
    const data = await res.json()
    if (data) {
      notify(`resources.account.helpers.adminSent`, { type: 'success' })
    }
  }

  /**
   *
   */
  const promoteUser = async () => {
    setLoading(true)
    await Api.post('partner/promote', { id: record?.id })
    setLoading(false)
    setShowPromoteDialog(false)
    notify(
      record.role === Role.Advertiser
        ? `resources.partner.helpers.promoteSuccess`
        : `resources.partner.helpers.promoteFailed`,
      {
        type: 'success',
        messageArgs: { smart_count: 1 }
      }
    )
    refresh()
  }

  /**
   *
   */
  const downgradePartner = async () => {
    setLoading(true)
    await Api.post('partner/downgrade', { id: record?.id })
    setLoading(false)
    setShowDowngradeDialog(false)
    notify(
      record.role === Role.Advertiser
        ? `resources.partner.helpers.downgradeSuccess`
        : `resources.partner.helpers.downgradeFailed`,
      {
        type: 'success',
        messageArgs: { smart_count: 1 }
      }
    )
    refresh()
  }

  return (
    <TopToolbar>
      {record?.role !== Role.Admin && (
        <Button
          style={{ color: record?.isBlocked ? '#888' : '#f44336' }}
          onClick={() => setShowBlockDialog(true)}
          label={record?.isBlocked ? 'resources.account.actions.unlock' : 'resources.account.actions.block'}>
          {record?.isBlocked ? <LockOpen /> : <Block />}
        </Button>
      )}
      {record?.role === Role.User && (
        <Button onClick={() => setShowPromoteDialog(true)} label={'resources.account.actions.promote'}>
          <ArrowUpward />
        </Button>
      )}
      {record?.role === Role.Advertiser && (
        <Button onClick={() => setShowDowngradeDialog(true)} label={'resources.account.actions.downgrade'}>
          <ArrowDownward />
        </Button>
      )}
      <Button onClick={() => setShowContactDialog(true)} label={'resources.account.actions.send'}>
        <Send />
      </Button>
      <EditButton />
      {/**/}
      <Dialog
        open={showBlockDialog}
        onClose={() => setShowBlockDialog(false)}
        aria-labelledby="alert-dialog-block-title"
        aria-describedby="alert-dialog-block-description">
        <DialogTitle id="alert-dialog-block-title">
          Êtes-vous sûr de vouloir {record?.isBlocked ? 'déverouiller' : 'bloquer'} cet utilisateur ?
        </DialogTitle>
        {record?.isBlocked && (
          <DialogContent>
            <DialogContentText id="alert-dialog-block-description">
              En cas d'utilisation abusive du service (triche, hack, etc.), vous pouvez bloquer un utilisateur ici. Lors de sa prochaine
              connexion ou interaction s'il est déjà connecté, un message s'affichera lui indiquant que son compte a été verrouillé.
            </DialogContentText>
          </DialogContent>
        )}
        <DialogActions style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Button color="primary" label="Annuler" onClick={() => setShowBlockDialog(false)} />
          <Button color="primary" variant="contained" label="Confirmer" disabled={loading} onClick={toggleLockUser} />
        </DialogActions>
      </Dialog>
      {/**/}
      <Dialog
        open={showContactDialog}
        onClose={() => setShowContactDialog(false)}
        aria-labelledby="alert-dialog-contact-title"
        aria-describedby="alert-dialog-contact-description">
        <DialogTitle id="alert-dialog-contact-title">Contacter un utilisateur</DialogTitle>
        <DialogContent>
          <MUITextField
            margin="dense"
            id="subject"
            value={subject}
            onChange={(e) => setSubject(e.target.value)}
            label={translate('resources.account.contact.subject')}
            fullWidth
          />
          <MUITextField
            multiline
            margin="dense"
            id="body"
            value={body}
            onChange={(e) => setBody(e.target.value)}
            label={translate('resources.account.contact.body')}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button color="primary" variant="contained" label="Envoyer" disabled={subject === '' || body === ''} onClick={contactUser} />
        </DialogActions>
      </Dialog>
      {/**/}
      <Dialog
        open={showPromoteDialog}
        onClose={() => setShowPromoteDialog(false)}
        aria-labelledby="alert-dialog-promote-title"
        aria-describedby="alert-dialog-promote-description">
        <DialogTitle id="alert-dialog-promote-title">Êtes-vous sûr de vouloir promouvoir cet utilisateur ?</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-promote-description">
            <Typography>
              Donner accès à un utilisateur le niveau "Annonceur" lui ouvre des droits sur le back-office. Il sera ainsi en mesure de
              consulter : ses Challenges et d'autres informations relatives à ses Challenges.
            </Typography>
            <Typography>
              L'ensemble des données du back-office n'est pas visible et reste accessible uniquement pour les administrateurs.
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Button color="primary" label="Annuler" onClick={() => setShowPromoteDialog(false)} />
          <Button color="primary" variant="contained" label="Promouvoir" disabled={loading} onClick={promoteUser} />
        </DialogActions>
      </Dialog>
      {/**/}
      <Dialog
        open={showDowngradeDialog}
        onClose={() => setShowDowngradeDialog(false)}
        aria-labelledby="alert-dialog-downgrade-title"
        aria-describedby="alert-dialog-downgrade-description">
        <DialogTitle id="alert-dialog-downgrade-title">Êtes-vous sûr de vouloir rétrograder cet annonceur ?</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-promote-description">
            <Typography>
              Supprimer l'accès à un utilisateur de niveau "Annonceur" lui supprime ses droits sur le back-office. Il ne sera ainsi plus en
              mesure de consulter : ses Challenges et d'autres informations relatives à ses Challenges.
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Button color="primary" label="Annuler" onClick={() => setShowDowngradeDialog(false)} />
          <Button color="primary" variant="contained" label="Rétrograder" disabled={loading} onClick={downgradePartner} />
        </DialogActions>
      </Dialog>
    </TopToolbar>
  )
}

const ChipRoleField = (props: any) => {
  const account = useRecordContext<Account>(props)
  const translate = useTranslate()
  if (account.role === Role.Admin) {
    return <Chip icon={<AdminPanelSettings />} color="primary" label={translate('resources.account.filters.admin')} />
  } else if (account.role === Role.Advertiser) {
    return <Chip icon={<MonetizationOn />} color="primary" label={translate('resources.account.filters.partner')} />
  } else {
    return <Chip color="default" label={translate('resources.account.filters.user')} />
  }
}

const ChipEmailConfirmedField = (props: any) => {
  const account = useRecordContext<Account>(props)
  const translate = useTranslate()
  if (account.emailValid) {
    return <Chip icon={<Verified />} color="primary" label={translate('resources.account.helpers.emailValid.ok')} />
  } else {
    return <Chip icon={<PriorityHigh />} color="warning" label={translate('resources.account.helpers.emailValid.ko')} />
  }
}

const ChipBlockedField = (props: any) => {
  const account = useRecordContext<Account>(props)
  const translate = useTranslate()
  if (account.isBlocked) {
    return <Chip icon={<Block />} color="error" label={translate('resources.account.helpers.isBlocked')} />
  } else {
    return <span />
  }
}

const PartnerChallenge = (props: any) => {
  const account = useRecordContext<Account>(props)
  const [sort] = useState<SortPayload>({ field: 'id', order: 'ASC' })
  const { data, isLoading, error } = useGetList('challenge', {
    pagination: { page: 1, perPage: 25 },
    sort,
    filter: { createdBy: account.id }
  })
  if (!data || isLoading) {
    return <Loading />
  }
  if (error) {
    return <p>Erreur: {error.message}</p>
  }
  return (
    <Datagrid data={data} sort={sort}>
      <NumberField source="id" label="resources.challenge.fields.id" />
      <TextField source="name" label="resources.challenge.fields.name" />
      <ShortDateTimeField source="startDate" label="resources.challenge.fields.startDate" />
      <ObjectiveField source="objective" label="resources.challenge.fields.objective" />
      <ChallengeOngoingField source="onGoing" label="resources.challenge.fields.onGoing" />
      <ChallengeStatusField source="status" label="resources.challenge.fields.status" />
      <ShowButton resource="challenge" />
    </Datagrid>
  )
}

const DynamicTabbedShowLayout = (props: any) => {
  const classes = useStyles()
  const translate = useTranslate()
  const createPath = useCreatePath()
  const account = useRecordContext<Account>(props)
  let intro = (
    <Tab label={translate('resources.account.tabs.intro')}>
      <Grid container>
        <Grid item xs={12}>
          <ChipRoleField source="role" />
          &nbsp;
          <ChipEmailConfirmedField source="role" />
          &nbsp;
          <ChipBlockedField source="isBlocked" />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Box p={2}>
            <Card className={classes.root}>
              <CardContent>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={12}>
                    <AvatarImageField source="image" maxHeight={100} useFallback />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Labeled label="resources.account.fields.email">
                      <TextField source="email" />
                    </Labeled>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Labeled label="resources.account.fields.pseudo">
                      <TextField source="pseudo" />
                    </Labeled>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Labeled label="resources.account.fields.firstName">
                      <TextField source="firstName" emptyText="(non renseigné)" />
                    </Labeled>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Labeled label="resources.account.fields.lastName">
                      <TextField source="lastName" emptyText="(non renseigné)" />
                    </Labeled>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Labeled label="resources.account.fields.postalCode">
                      <TextField source="postalCode" emptyText="(non renseigné)" />
                    </Labeled>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Labeled label="resources.account.fields.city">
                      <TextField source="city" emptyText="(non renseigné)" />
                    </Labeled>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Labeled label="resources.account.fields.phone">
                      <TextField source="phone" emptyText="(non renseigné)" />
                    </Labeled>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Labeled label="resources.account.fields.creationDate">
                      <ShortDateField source="creationDate" />
                    </Labeled>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Box p={2}>
            <Card className={classes.root}>
              <CardContent>
                {[
                  'notifiable',
                  'notifChallengeNew',
                  'notifChallengeEndSoon',
                  'notifSoloNotLeading',
                  'notifTeamNotLeading',
                  'notifTeamWon',
                  'notifFromQueeny',
                  'notifFromPartners'
                ].map((key) => (
                  <div key={key} className={classes.flexRow}>
                    <BooleanField source={key} style={{ marginRight: 10 }} />
                    <Typography style={{ flexGrow: 1 }}>{translate(`resources.account.fields.${key}`)}</Typography>
                  </div>
                ))}
              </CardContent>
            </Card>
          </Box>
        </Grid>
      </Grid>
      <Box p={2}>
        <Card className={classes.root}>
          <CardContent>
            <Labeled label="resources.account.helpers.devices">
              <ReferenceManyField reference="device" target="account.id">
                <Datagrid bulkActionButtons={false}>
                  <TextField source="id" />
                  <TextField source="brand" />
                  <TextField source="model" />
                  <OsField source="os" sortable={false} />
                  <ShortDateTimeField source="creationDate" />
                  <ShortDateTimeField source="updateDate" emptyText="(non renseigné)" />
                  <ShowButton resource="device" label="Voir" />
                </Datagrid>
              </ReferenceManyField>
            </Labeled>
          </CardContent>
        </Card>
      </Box>
    </Tab>
  )
  let sessions = (
    <Tab label={translate('resources.account.tabs.sessions')}>
      <ReferenceManyField reference="session" target="account.id" label="resources.account.helpers.sessions" pagination={<Pagination />}>
        <Datagrid bulkActionButtons={false} rowStyle={SessionRowStyle}>
          <ShortDateTimeField source="deviceStartDate" />
          <ShortDateTimeField source="deviceEndDate" />
          <SessionEndedByField source="endedBy" />
          <SessionLinkChallengeField source="challenge" />
          <SessionScoreField source="score" />
          <SessionLinkDeviceField source="device" />
          <FunctionField
            label={'resources.session.fields.cscDevice'}
            render={(record: RaRecord) => {
              if (record.cscDevice) {
                return (
                  <Link to={createPath({ resource: 'csc-device', type: 'show', id: record.cscDevice.id })}>{record.cscDevice.name}</Link>
                )
              } else {
                return null
              }
            }}
          />
          <ShowButton resource="session" label="Voir" />
        </Datagrid>
      </ReferenceManyField>
    </Tab>
  )
  let challenges = (
    <Tab label={translate('resources.account.tabs.challenges')}>
      <ReferenceManyField
        reference="challenge"
        target="account.id"
        label="resources.account.helpers.challenges"
        pagination={<Pagination />}>
        <Datagrid bulkActionButtons={false}>
          <NumberField source="id" />
          <TextField source="name" />
          <ChallengeOngoingField source="onGoing" sortable={false} />
          <ChallengeStatusField source="status" />
          <ShowButton resource="challenge" label="Voir" />
        </Datagrid>
      </ReferenceManyField>
    </Tab>
  )
  if (account?.role === Role.Advertiser) {
    return (
      <TabbedShowLayout>
        {intro}
        {sessions}
        {challenges}
        <Tab label={translate('resources.account.tabs.partner')}>
          <Grid container>
            <Grid item xs={12}>
              <PartnerChallenge />
            </Grid>
          </Grid>
        </Tab>
      </TabbedShowLayout>
    )
  } else {
    return (
      <TabbedShowLayout>
        {intro}
        {sessions}
        {challenges}
      </TabbedShowLayout>
    )
  }
}

const AccountShow = (props: ShowProps) => {
  return (
    <Show title={<Title />} actions={<AccountShowActions />}>
      <DynamicTabbedShowLayout {...props} />
    </Show>
  )
}

export default AccountShow
