import type { RaRecord } from 'react-admin'

/* https://github.com/marmelab/react-admin/blob/v4.16.6/docs/AuthProviderWriting.md
 * Rendu par authProvider.getPermissions() (et hook usePermissions()).
 * Format libre.
 */
// éviter le nom 'Permissions', déjà pris en typescript\lib\lib.dom.d.ts
export interface AppPermissions {
  permissions: any[]
  roles: string[]
}

export interface Image extends RaRecord {
  creationDate: Date
  filename: string
  averageColor: string
  averageColorIsDark: boolean
  updateDate: Date | null
}

export enum Role {
  Advertiser = 'advertiser',
  Admin = 'admin',
  User = 'user'
}

export interface Account extends RaRecord {
  emailValid: boolean
  city: string | null
  creationDate: Date
  device: Device
  image: Image | null
  isBlocked: boolean
  sessions: Session[]
  notifiable: boolean
  notifChallengeNew: boolean
  notifChallengeEndSoon: boolean
  notifSoloNotLeading: boolean
  notifTeamNotLeading: boolean
  notifTeamWon: boolean
  notifFromQueeny: boolean
  notifFromPartners: boolean
  phone: string | null
  postalCode: string | null
  role: Role
  updateDate: Date | null
}

export interface Device extends RaRecord {
  account: Account
  os: 'android' | 'ios'
  osVersion: string
  brand: string
  model: string
  creationDate: Date
  updateDate: Date | null
}

export interface Team extends RaRecord {
  accounts: Account[]
  creationDate: Date
  name: string
  updateDate: Date | null
}

export enum Status {
  Participant = 'participant',
  Winner = 'winner'
}

export interface AccountWithSum extends Account {
  sum: number
}

export interface TeamForRanking {
  accountsWithSum: AccountWithSum[]
}

export interface Rank extends RaRecord {
  team: TeamForRanking
  score: number
  challenge: Challenge
}

export interface Prize extends RaRecord {
  challenge: Challenge
  creationDate: Date
  prizeOrder: number
  image: Image
  team: Team | null
  updateDate: Date | null
}

export enum ChallengeStatus {
  ACTIVE = 'active',
  CLOSED = 'closed',
  DELETED = 'deleted',
  DRAFT = 'draft',
  TO_VALIDATE = 'to_validate'
}

export interface Challenge extends RaRecord {
  code: string | null
  createdBy: Account
  creationDate: Date
  criteria: 'calories' | 'distance' | 'duration'
  deletedAt: Date | null
  description: string
  email: string
  endDate: Date
  global: boolean
  images: Image[]
  maxTeamSize: number | null
  name: string
  objective: number
  onlyAdminCanCreateTeams: boolean
  prizes: Prize[]
  scope: 'public' | 'private'
  sessionDuration: number
  startDate: Date
  status: ChallengeStatus
  teams: Team[]
  type: 'solo' | 'team'
  updateDate: Date | null
  url: string
}

export interface Partner extends RaRecord {
  creationDate: Date
  email: string
  firstName: string
  lastName: string
  company: string
}

export interface Order extends RaRecord {
  creationDate: Date
  challenge: Challenge
  account: Account
  updateDate: Date | null
}

export enum EndedBy {
  API_SEND_DATA_WIN = 'api_send_data_win',
  API_STOP = 'api_stop',
  BLUETOOTH_LOST = 'bluetooth_lost',
  FORCE = 'force',
  MANUAL = 'manual',
  SERVER_STOP_GHOST_SESSION = 'server_stop_ghost_session',
  SERVER_STOP_SESSION_TOO_OLD = 'server_stop_session_too_old',
  SUSPICIOUS = 'suspicious',
  TIME_UP = 'time_up',
  TIMEOUT = 'timeout',
  WEBSOCKET_DISCONNECTED = 'websocket_disconnected'
}

export interface Rpm extends RaRecord {
  calories: number
  date: Date
  distance: number
  rpm: number
}

export interface CscDevice extends RaRecord {
  c1: boolean
  c2: boolean
  manufacturerName?: string
  name?: string
  batteryLevel?: number
  sessions: Session[]
}

export interface Session extends RaRecord {
  account: Account
  cal: number
  challenge: Challenge
  creationDate: Date
  cscDevice: CscDevice | null
  deviceEndDate: Date
  deviceStartDate: Date
  distance: number
  duration: number
  endedBy: EndedBy
  latitude: number | null
  longitude: number | null
  rpms: Rpm[]
  updateDate: Date | null
}

export enum AuditAction {
  ADMIN_CREATE_CHALLENGE = 'admin_create_challenge',
  ADMIN_DELETE_CHALLENGE = 'admin_delete_challenge',
  ADMIN_UNAUTHORIZED_ACCESS_TO_RESOURCE = 'admin_unauthorized_access_to_resource',
  ADMIN_UPDATE_CHALLENGE = 'admin_update_challenge',
  ADMIN_VALIDATE_CHALLENGE = 'admin_validate_challenge',
  CHALLENGE_WON = 'challenge_won',
  PARTNER_CHALLENGE_CREATE = 'partner_challenge_create',
  PARTNER_CHALLENGE_UPDATE = 'partner_challenge_update',
  PARTNER_NEW_ORDER = 'partner_new_order',
  PARTNER_REGISTER = 'partner_register',
  PARTNER_UNAUTHORIZED_ACCESS_TO_RESOURCE = 'partner_unauthorized_access_to_resource',
  SESSION_START_FAILED_NO_ACCOUNT = 'session_start_failed_no_account',
  SESSION_START_FAILED_NO_CHALLENGE = 'session_start_failed_no_challenge',
  SESSION_START_KO = 'session_start_ko',
  SESSION_START_OK = 'session_start_ok',
  SESSION_STOP = 'session_stop',
  SESSION_SUSPICIOUS = 'session_suspicious',
  USER_BLOCKED_TRIED_TO_LOGIN = 'user_blocked_tried_to_login',
  USER_DELETE_ACCOUNT = 'user_delete_account',
  USER_FAILED_TO_LOGIN = 'user_failed_to_login',
  USER_LOGIN = 'user_login',
  USER_LOGOUT = 'user_logout',
  USER_OPEN_APP = 'user_open_app',
  USER_REFRESHING_TOKENS = 'user_refreshing_tokens',
  USER_REGISTER = 'user_register',
  USER_REQUEST_NEW_PASSWORD = 'user_request_new_password',
  USER_REQUEST_PASSWORD_CHANGED = 'user_request_password_changed',
  USER_UNLOCKED_CHALLENGE = 'user_unlocked_challenge',
  USER_UPDATE_PROFILE = 'user_update_profile'
}

export interface Audit extends RaRecord {
  type: AuditAction
  description: string
  creationDate: Date
  entityId: number
}

// Copied from @nestjsx/crud
export enum CrudActions {
  ReadAll = 'Read-All',
  ReadOne = 'Read-One',
  CreateOne = 'Create-One',
  CreateMany = 'Create-Many',
  UpdateOne = 'Update-One',
  ReplaceOne = 'Replace-One',
  DeleteOne = 'Delete-One',
  DeleteAll = 'Delete-All',
  RecoverOne = 'Recover-One'
}

export enum NotificationCategory {
  // Topics, like when users are subscrided to some channels
  CHALLENGE_NEW = 'CHALLENGE_NEW',
  CHALLENGE_END_SOON = 'CHALLENGE_END_SOON',
  FROM_QUEENY = 'FROM_QUEENY',
  FROM_PARTNERS = 'FROM_PARTNERS',
  // Single or multiple users when a particular event occurs
  CHALLENGE_REWARD_WON = 'CHALLENGE_REWARD_WON'
}

export interface Notification extends RaRecord {
  body: string
  createdBy: Account
  creationDate: Date
  id: number
  immediate: boolean
  logs: string
  recipient: string
  sentDate: Date
  title: string
  topic: NotificationCategory
  type: 'device' | 'email' | 'both'
  updateDate?: Date
}

export enum ContactSubjectEnum {
  BUG = 'bug',
  EVOLUTION = 'evolution',
  REWARD = 'reward',
  LOVE = 'love',
  OTHER = 'other'
}

export interface Contact extends RaRecord {
  creationDate: Date
  processed: boolean
  subject: ContactSubjectEnum
  updateDate?: Date
}

declare global {
  interface Window {
    restServer: any
  }
}
