import React from 'react'
import {
  BooleanField,
  Datagrid,
  List,
  type ListProps,
  NumberField,
  Pagination,
  ReferenceField,
  ShowButton,
  TextField,
  TopToolbar
} from 'react-admin'
import { ContactSubjectField } from '../components/ContactFields'
import { ShortDateField } from '../components/ShortDateTimeField'

const ContactListActions = (props: any) => <TopToolbar />

const ContactList = (props: ListProps) => (
  <List
    {...props}
    perPage={20}
    pagination={<Pagination rowsPerPageOptions={[20, 50, 100]} />}
    sort={{ field: 'id', order: 'DESC' }}
    actions={<ContactListActions />}>
    <Datagrid bulkActionButtons={false}>
      <NumberField source="id" />
      <ContactSubjectField source="subject" />
      <ReferenceField source="account.id" reference="account">
        <TextField source="pseudo" />
      </ReferenceField>
      <BooleanField source="processed" />
      <ShortDateField source="creationDate" />
      <ShortDateField source="updateDate" />
      <ShowButton label="Voir" />
    </Datagrid>
  </List>
)

export default ContactList
