import People from '@mui/icons-material/People'
import TeamEdit from './edit'
import TeamList from './list'
import TeamShow from './show'

const exp = {
  edit: TeamEdit,
  list: TeamList,
  icon: People,
  show: TeamShow
}

export default exp
