import React from 'react'
import {
  maxLength,
  minValue,
  required,
  BooleanInput,
  Create,
  SimpleForm,
  TextInput,
  DateTimeInput,
  FormDataConsumer,
  SelectInput
} from 'react-admin'
import { Grid } from '@mui/material'

const NotificationCreate = () => {
  return (
    <Create redirect="list">
      <SimpleForm>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <TextInput fullWidth source="title" validate={[required()]} />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextInput fullWidth source="body" validate={[maxLength(255), required()]} />
          </Grid>
          <Grid item xs={12} md={6}>
            <SelectInput
              fullWidth
              source="topic"
              choices={[
                {
                  id: 'FROM_QUEENY',
                  name: 'resources.notification.topic.fromQueeny'
                },
                {
                  id: 'FROM_PARTNERS',
                  name: 'resources.notification.topic.fromPartners'
                }
              ]}
              validate={[required()]}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <SelectInput
              fullWidth
              source="type"
              defaultValue={'device'}
              disabled
              choices={[
                {
                  id: 'device',
                  name: 'resources.notification.type.device'
                },
                {
                  id: 'email',
                  name: 'resources.notification.type.email'
                },
                {
                  id: 'both',
                  name: 'resources.notification.type.both'
                }
              ]}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <BooleanInput fullWidth source="immediate" defaultValue={true} />
          </Grid>
          <Grid item xs={12} md={6}>
            <FormDataConsumer>
              {({ formData, ...rest }) =>
                formData.immediate === false && <DateTimeInput fullWidth source="sentDate" validate={[required(), minValue(new Date())]} />
              }
            </FormDataConsumer>
          </Grid>
        </Grid>
      </SimpleForm>
    </Create>
  )
}

export default NotificationCreate
