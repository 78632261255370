import Payment from '@mui/icons-material/Payment'
import OrderList from './list'
import OrderShow from './show'

const exp = {
  list: OrderList,
  icon: Payment,
  show: OrderShow
}

export default exp
