import { Person } from '@mui/icons-material'
import AccountEdit from './edit'
import AccountList from './list'
import AccountShow from './show'

const exp = {
  edit: AccountEdit,
  icon: Person,
  list: AccountList,
  show: AccountShow
}

export default exp
