import Smartphone from '@mui/icons-material/Smartphone'
import DeviceList from './list'
import DeviceShow from './show'

const exp = {
  list: DeviceList,
  icon: Smartphone,
  show: DeviceShow
}

export default exp
