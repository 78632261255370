import React from 'react'
import {
  Datagrid,
  Link,
  List,
  type ListProps,
  Pagination,
  SearchInput,
  SelectArrayInput,
  TextField,
  useCreatePath,
  useGetOne,
  useRecordContext,
  useTranslate
} from 'react-admin'
import {
  AdminPanelSettings,
  BugReport,
  Edit,
  EmojiEvents,
  HowToReg,
  LockOpen,
  Login,
  Logout,
  Password,
  PersonRemove,
  PriorityHigh,
  ShoppingCart,
  TimerOffOutlined,
  TimerOutlined,
  TouchApp,
  Warning
} from '@mui/icons-material'
import { ShortDateTimeField } from '../components/ShortDateTimeField'
import { type Audit, AuditAction } from '../types'
import { Chip } from '@mui/material'

const AuditFilters = () => {
  const translate = useTranslate()
  return [
    <SearchInput fullWidth source="description" placeholder="Description" alwaysOn />,
    <SelectArrayInput
      alwaysOn
      source="type"
      choices={[
        { id: AuditAction.ADMIN_CREATE_CHALLENGE, name: translate('resources.audit.filters.admin_create_challenge') },
        { id: AuditAction.ADMIN_DELETE_CHALLENGE, name: translate('resources.audit.filters.admin_delete_challenge') },
        {
          id: AuditAction.ADMIN_UNAUTHORIZED_ACCESS_TO_RESOURCE,
          name: translate('resources.audit.filters.admin_unauthorized_access_to_resource')
        },
        { id: AuditAction.ADMIN_UPDATE_CHALLENGE, name: translate('resources.audit.filters.admin_update_challenge') },
        { id: AuditAction.ADMIN_VALIDATE_CHALLENGE, name: translate('resources.audit.filters.admin_validate_challenge') },
        { id: AuditAction.CHALLENGE_WON, name: translate('resources.audit.filters.challenge_won') },
        { id: AuditAction.PARTNER_CHALLENGE_CREATE, name: translate('resources.audit.filters.partner_challenge_create') },
        { id: AuditAction.PARTNER_CHALLENGE_UPDATE, name: translate('resources.audit.filters.partner_challenge_update') },
        { id: AuditAction.PARTNER_NEW_ORDER, name: translate('resources.audit.filters.partner_new_order') },
        { id: AuditAction.PARTNER_REGISTER, name: translate('resources.audit.filters.partner_register') },
        {
          id: AuditAction.PARTNER_UNAUTHORIZED_ACCESS_TO_RESOURCE,
          name: translate('resources.audit.filters.partner_unauthorized_access_to_resource')
        },
        { id: AuditAction.SESSION_START_FAILED_NO_ACCOUNT, name: translate('resources.audit.filters.session_start_failed_no_account') },
        { id: AuditAction.SESSION_START_FAILED_NO_CHALLENGE, name: translate('resources.audit.filters.session_start_failed_no_challenge') },
        { id: AuditAction.SESSION_START_KO, name: translate('resources.audit.filters.session_start_ko') },
        { id: AuditAction.SESSION_START_OK, name: translate('resources.audit.filters.session_start_ok') },
        { id: AuditAction.SESSION_STOP, name: translate('resources.audit.filters.session_stop') },
        { id: AuditAction.SESSION_SUSPICIOUS, name: translate('resources.audit.filters.session_suspicious') },
        { id: AuditAction.USER_BLOCKED_TRIED_TO_LOGIN, name: translate('resources.audit.filters.user_blocked_tried_to_login') },
        { id: AuditAction.USER_DELETE_ACCOUNT, name: translate('resources.audit.filters.user_delete_account') },
        { id: AuditAction.USER_FAILED_TO_LOGIN, name: translate('resources.audit.filters.user_failed_to_login') },
        { id: AuditAction.USER_LOGIN, name: translate('resources.audit.filters.user_login') },
        { id: AuditAction.USER_LOGOUT, name: translate('resources.audit.filters.user_logout') },
        { id: AuditAction.USER_OPEN_APP, name: translate('resources.audit.filters.user_open_app') },
        { id: AuditAction.USER_REFRESHING_TOKENS, name: translate('resources.audit.filters.user_refreshing_tokens') },
        { id: AuditAction.USER_REGISTER, name: translate('resources.audit.filters.user_register') },
        { id: AuditAction.USER_UNLOCKED_CHALLENGE, name: translate('resources.audit.filters.user_unlocked_challenge') },
        { id: AuditAction.USER_UPDATE_PROFILE, name: translate('resources.audit.filters.user_update_profile') }
      ]}
    />
  ]
}

const AuditActionType = (props: any) => {
  const audit = useRecordContext<Audit>(props)
  const translate = useTranslate()
  const label = translate(`resources.audit.filters.${audit.type}`)
  switch (audit.type) {
    default:
    case AuditAction.ADMIN_CREATE_CHALLENGE:
    case AuditAction.ADMIN_DELETE_CHALLENGE:
    case AuditAction.ADMIN_UPDATE_CHALLENGE:
    case AuditAction.ADMIN_VALIDATE_CHALLENGE:
      return <Chip color="info" icon={<AdminPanelSettings />} label={label} />
    case AuditAction.ADMIN_UNAUTHORIZED_ACCESS_TO_RESOURCE:
    case AuditAction.PARTNER_UNAUTHORIZED_ACCESS_TO_RESOURCE:
    case AuditAction.USER_BLOCKED_TRIED_TO_LOGIN:
      return <Chip color="error" icon={<PriorityHigh />} label={label} />
    case AuditAction.CHALLENGE_WON:
      return <Chip color="success" icon={<EmojiEvents />} label={label} />
    case AuditAction.PARTNER_CHALLENGE_CREATE:
    case AuditAction.PARTNER_CHALLENGE_UPDATE:
    case AuditAction.PARTNER_NEW_ORDER:
    case AuditAction.PARTNER_REGISTER:
      return <Chip color="info" icon={<ShoppingCart />} label={label} />
    case AuditAction.SESSION_START_FAILED_NO_ACCOUNT:
    case AuditAction.SESSION_START_FAILED_NO_CHALLENGE:
    case AuditAction.SESSION_START_KO:
      return <Chip color="warning" label={label} />
    case AuditAction.SESSION_START_OK:
      return <Chip icon={<TimerOutlined />} label={label} />
    case AuditAction.SESSION_STOP:
      return <Chip icon={<TimerOffOutlined />} label={label} />
    case AuditAction.SESSION_SUSPICIOUS:
      return <Chip color="warning" icon={<BugReport />} label={label} />
    case AuditAction.USER_FAILED_TO_LOGIN:
      return <Chip color="warning" icon={<Warning />} label={label} />
    case AuditAction.USER_DELETE_ACCOUNT:
      return <Chip color="info" icon={<PersonRemove />} label={label} />
    case AuditAction.USER_LOGIN:
      return <Chip icon={<Login />} label={label} />
    case AuditAction.USER_LOGOUT:
      return <Chip icon={<Logout />} label={label} />
    case AuditAction.USER_OPEN_APP:
      return <Chip icon={<TouchApp />} label={label} />
    case AuditAction.USER_REFRESHING_TOKENS:
      return <Chip label={label} />
    case AuditAction.USER_REGISTER:
      return <Chip color="info" icon={<HowToReg />} label={label} />
    case AuditAction.USER_REQUEST_NEW_PASSWORD:
    case AuditAction.USER_REQUEST_PASSWORD_CHANGED:
      return <Chip color="info" icon={<Password />} label={label} />
    case AuditAction.USER_UNLOCKED_CHALLENGE:
      return <Chip color="info" icon={<LockOpen />} label={label} />
    case AuditAction.USER_UPDATE_PROFILE:
      return <Chip color="info" icon={<Edit />} label={label} />
  }
}

const userActions = [
  AuditAction.ADMIN_UNAUTHORIZED_ACCESS_TO_RESOURCE,
  AuditAction.PARTNER_NEW_ORDER,
  AuditAction.PARTNER_REGISTER,
  AuditAction.PARTNER_UNAUTHORIZED_ACCESS_TO_RESOURCE,
  AuditAction.SESSION_START_FAILED_NO_ACCOUNT,
  AuditAction.SESSION_START_KO,
  AuditAction.USER_BLOCKED_TRIED_TO_LOGIN,
  AuditAction.USER_FAILED_TO_LOGIN,
  AuditAction.USER_LOGIN,
  AuditAction.USER_LOGOUT,
  AuditAction.USER_OPEN_APP,
  AuditAction.USER_REFRESHING_TOKENS,
  AuditAction.USER_REGISTER,
  AuditAction.USER_REQUEST_NEW_PASSWORD,
  AuditAction.USER_REQUEST_PASSWORD_CHANGED,
  AuditAction.USER_UNLOCKED_CHALLENGE,
  AuditAction.USER_UPDATE_PROFILE
]
const sessionActions = [AuditAction.SESSION_START_OK, AuditAction.SESSION_STOP, AuditAction.SESSION_SUSPICIOUS]

const EntityLinkField = (props: any) => {
  const audit = useRecordContext<Audit>(props)
  let resource = ''
  if (userActions.includes(audit.type)) {
    resource = 'account'
  } else if (sessionActions.includes(audit.type)) {
    resource = 'session'
  } else {
    resource = 'challenge'
  }
  const { data, isLoading } = useGetOne(resource, { id: audit.entityId })
  const createPath = useCreatePath()
  if (data && !isLoading) {
    switch (resource) {
      default:
      case 'account':
        return <Link to={createPath({ resource, type: 'show', id: audit.entityId })}>{data.email}</Link>
      case 'session':
        return <Link to={createPath({ resource, type: 'show', id: audit.entityId })}>{data.id}</Link>
      case 'challenge':
        return <Link to={createPath({ resource, type: 'show', id: audit.entityId })}>{data.name}</Link>
    }
  } else {
    return null
  }
}

const AuditList = (props: ListProps) => {
  return (
    <List
      {...props}
      title={'Audit de la base de données'}
      filters={AuditFilters()}
      perPage={25}
      pagination={<Pagination rowsPerPageOptions={[25, 50, 100]} />}
      sort={{ field: 'id', order: 'DESC' }}>
      <Datagrid bulkActionButtons={false}>
        <TextField source="id" />
        <AuditActionType source="type" />
        <TextField source="description" />
        <ShortDateTimeField source="creationDate" />
        <EntityLinkField source="entityId" />
      </Datagrid>
    </List>
  )
}

export default AuditList
