import React from 'react'
import { Datagrid, NumberField, ReferenceField, SelectField, TextField, useRecordContext, useTranslate } from 'react-admin'
import { Chip, Typography } from '@mui/material'
import { AdminPanelSettings, EmojiEvents } from '@mui/icons-material'

import { type Notification, NotificationCategory } from '../types'

const NotificationCategoryChip = (props: any) => {
  const translate = useTranslate()
  const notif = useRecordContext<Notification>(props)
  switch (notif.topic) {
    case NotificationCategory.CHALLENGE_NEW:
      return <Chip color="default" icon={<AdminPanelSettings />} label={translate('resources.notification.topic.challengeNew')} />
    case NotificationCategory.CHALLENGE_END_SOON:
      return <Chip color="default" label={translate('resources.notification.topic.challengeEndSoon')} />
    case NotificationCategory.FROM_QUEENY:
      return <Chip color="default" label={translate('resources.notification.topic.fromQueeny')} />
    case NotificationCategory.FROM_PARTNERS:
      return <Chip color="default" label={translate('resources.notification.topic.fromPartners')} />
    case NotificationCategory.CHALLENGE_REWARD_WON:
      return <Chip color="success" icon={<EmojiEvents />} label={translate('resources.notification.topic.challengeRewardWon')} />
  }
}

const NotificationCategoryField = (props: any) => {
  const translate = useTranslate()
  const choices = [
    { id: NotificationCategory.CHALLENGE_NEW, name: translate('resources.notification.topic.challengeNew') },
    { id: NotificationCategory.CHALLENGE_END_SOON, name: translate('resources.notification.topic.challengeEndSoon') },
    { id: NotificationCategory.FROM_QUEENY, name: translate('resources.notification.topic.fromQueeny') },
    { id: NotificationCategory.FROM_PARTNERS, name: translate('resources.notification.topic.fromPartners') },
    { id: NotificationCategory.CHALLENGE_REWARD_WON, name: translate('resources.notification.topic.challengeRewardWon') }
  ]
  return <SelectField source="topic" choices={choices} />
}

const NoRecipient = () => {
  return (
    <div>
      <Typography variant="body1">Aucun destinataire en particulier pour cette notification</Typography>
      <Typography variant="body1">
        Cette notification a probablement été envoyée aux personnes abonnées à un Sujet spécifique (cf Catégorie)
      </Typography>
    </div>
  )
}

const NotificationRecipientField = (props: any) => {
  const notif = useRecordContext<Notification>(props)
  let data = notif.recipient?.split(',').map((id) => ({ id }))
  return (
    <Datagrid data={data} sort={{ field: 'id', order: 'ASC' }} empty={<NoRecipient />}>
      <NumberField source="id" />
      <ReferenceField source="id" reference="account" label="resources.account.fields.pseudo">
        <TextField source="pseudo" />
        &nbsp;(
        <TextField source="email" />)
      </ReferenceField>
    </Datagrid>
  )
}

export { NotificationCategoryChip, NotificationCategoryField, NotificationRecipientField }
