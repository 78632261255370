import { Admin, CustomRoutes, Resource } from 'react-admin'
import { Route } from 'react-router-dom'
import polyglotI18nProvider from 'ra-i18n-polyglot'
import frenchMessages from 'ra-language-french'

import './index.css'
import * as domainMessages from './i18n'
import authProvider from './core/authProvider'
import DataProviderImpl from './core/dataProvider'
import account from './account'
import audit from './audit'
import challenge from './challenge'
import contact from './contact'
import cscDevice from './cscDevice'
import device from './device'
import notification from './notification'
import partner from './partner'
import prize from './prize'
import session from './session'
import team from './team'
import order from './order'
import Dashboard from './dashboard'
import ChallengeRanking from './challenge/ranking'
import ChallengeSessions from './challenge/sessions'
import ChallengeStats from './challenge/stats'
import Layout from './customAdminUi/Layout'
import LoginPageWithForgotPassword from './customAdminUi/LoginPageWithForgotPassword'
import ConfirmAccount from './customRoutes/ConfirmAccount'
import ForgotPassword from './customRoutes/ForgotPassword'
import ResetPassword from './customRoutes/ResetPassword'
import ProfileEdit from './customRoutes/profile/edit'
import ConfirmPartner from './customRoutes/ConfirmPartner'
import ProfileProvider from './core/ProfileContext'
import DeviceStats from './device/stats'
import type { AppPermissions } from './types'

const messages = {
  ...frenchMessages,
  ...domainMessages.fr
}

const i18nProvider = polyglotI18nProvider(() => messages, 'fr')

const knownResources = [
  <Resource name="account" {...account} />,
  <Resource name="challenge" {...challenge} />,
  <Resource name="team" {...team} />,
  <Resource name="csc-device" {...cscDevice} />,
  <Resource name="session" {...session} />,
  <Resource name="prize" {...prize} />,
  <Resource name="notification" {...notification} />,
  <Resource name="device" {...device} />,
  <Resource name="contact" {...contact} />,
  <Resource name="partner" {...partner} />,
  <Resource name="order" {...order} />,
  <Resource name="audit" {...audit} />
]

const customRoutes = [
  <CustomRoutes key="ranking">
    <Route path="/challenge/:id/ranking" element={<ChallengeRanking />} />
  </CustomRoutes>,
  <CustomRoutes key="sessions">
    <Route path="/challenge/:id/sessions/:criteria" element={<ChallengeSessions />} />
  </CustomRoutes>,
  <CustomRoutes key="challenge-stats">
    <Route path="/challenge/:id/stats" element={<ChallengeStats />} />
  </CustomRoutes>,
  <CustomRoutes key="device-stats">
    <Route path="/device/stats" element={<DeviceStats />} />
  </CustomRoutes>,
  <CustomRoutes key="profile">
    <Route path="/profile" element={<ProfileEdit />} />
  </CustomRoutes>,
  <CustomRoutes noLayout key="confirm-account">
    <Route path="/confirm-account/:token/:email" element={<ConfirmAccount />} />
  </CustomRoutes>,
  <CustomRoutes noLayout key="confirm-partner">
    <Route path="/confirm-partner/:token/:email" element={<ConfirmPartner />} />
  </CustomRoutes>,
  <CustomRoutes noLayout key="forgot-password">
    <Route path="/forgot-password" element={<ForgotPassword />} />
  </CustomRoutes>,
  <CustomRoutes noLayout key="reset-password">
    <Route path="/reset-password/:token" element={<ResetPassword />} />
  </CustomRoutes>
]

const fetchResources = (permissions: AppPermissions) => {
  if (permissions && permissions.permissions && permissions.permissions.length > 0) {
    const resources = permissions.permissions.map(p => p.resource)
    return knownResources.filter(resource => resources.includes(resource.props.name))
  } else {
    return []
  }
}

const App = () => (
  <ProfileProvider>
    <Admin
      disableTelemetry
      dashboard={Dashboard}
      i18nProvider={i18nProvider}
      authProvider={authProvider}
      dataProvider={DataProviderImpl(process.env.REACT_APP_API_ENDPOINT || 'localhost:8080/api/v3')}
      loginPage={LoginPageWithForgotPassword}
      layout={Layout}>
      {/* https://github.com/marmelab/react-admin/blob/v4.16.6/docs/Admin.md#using-a-function-as-admin-child
          If you also defined an authProvider, the child function will receive the result of a call to authProvider.getPermissions()
       */
        // valable en 17, n'est plus admis en @types/react 18(.2.51)
        // @ts-expect-error: Type '(permissions: AppPermissions) => JSX.Element[]' is not assignable to type 'ReactNode'.ts(2322)
        fetchResources}
      {customRoutes}
    </Admin>
  </ProfileProvider>
)

export default App
