import ThumbUp from '@mui/icons-material/ThumbUp'
import PartnerCreate from './create'
import PartnerList from './list'

const exp = {
  create: PartnerCreate,
  list: PartnerList,
  icon: ThumbUp
}

export default exp
