import Send from '@mui/icons-material/Send'
import ContactList from './list'
import ContactShow from './show'

const exp = {
  icon: Send,
  list: ContactList,
  show: ContactShow
}

export default exp
