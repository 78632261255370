import React from 'react'
import {
  FunctionField,
  Link,
  type RaRecord,
  useCreatePath,
  useRecordContext
} from 'react-admin'
import { Chip, IconButton, Tooltip } from '@mui/material'
import { Collections, Done, OpenInNew, PictureAsPdf } from '@mui/icons-material'

import { type Prize, Status } from '../types'

const PrizeTeamStatusField = (props: any) => (
  <FunctionField
    label="resources.score.fields.status"
    render={(record?: RaRecord) => {
      if (record && record.team) {
        if (record.team.status === Status.Winner) {
          return <Chip label="Remporté" color="secondary" onDelete={() => {}} deleteIcon={<Done />} />
        } else {
          return <Chip label="Participant" color="primary" />
        }
      } else {
        return null
      }
    }}
  />
)

const PrizeTeamLinkField = (props: any) => {
  const createPath = useCreatePath()
  return (
    <FunctionField
      render={(record?: RaRecord) => {
        if (record && record.team) {
          if (record.team.status === Status.Winner) {
            return <Link to={createPath({ resource: 'team', type: 'show', id: record.team.id })}>Voir l'équipe gagnante</Link>
          }
        } else {
          return null
        }
      }}
    />
  )
}

const PrizeImageIcon = (props: any) => {
  const record = useRecordContext<Prize>(props)
  if (record && record.image) {
    const isPDF = record.image.filename.endsWith('pdf')
    return (
      <Tooltip title={isPDF ? 'Ce lot est au format PDF' : 'Ce lot est au format image'}>
        <IconButton aria-label="Collections" color="inherit">
          {isPDF ? <PictureAsPdf /> : <Collections />}
        </IconButton>
      </Tooltip>
    )
  } else {
    return null
  }
}

const PrizeImageField = (props: any) => {
  const record = useRecordContext<Prize>(props)
  if (record && record.image) {
    if (record.image.filename.endsWith('pdf')) {
      return (
        <p>
          <span>Télécharger dans un nouvel onglet</span>
          <IconButton aria-label="OpenInNew" color="inherit" onClick={() => window.open(record.image.filename)}>
            <OpenInNew />
          </IconButton>
        </p>
      )
    } else {
      return <img src={record.image.filename} alt={record.image.filename} height="300" />
    }
  } else {
    return null
  }
}

export { PrizeTeamStatusField, PrizeTeamLinkField, PrizeImageIcon, PrizeImageField }
