import React from 'react'
import {
  DeleteButton,
  EditButton,
  FunctionField,
  NumberField,
  ReferenceField,
  Show,
  type ShowProps,
  SimpleShowLayout,
  TextField,
  TopToolbar,
  useTranslate
} from 'react-admin'
import { FormControl, FormHelperText, Grid, Typography } from '@mui/material'

import { ShortDateTimeField } from '../components/ShortDateTimeField'
import { RpmGraph, SessionEndedByField, SessionLinkAccountField, SessionLinkChallengeField } from '../components/SessionFields'
import type { CscDevice, Device } from '../types'

const SessionShowActions = (props: any) => (
  <TopToolbar>
    <EditButton record={props.data} />
    <DeleteButton record={props.data} mutationMode="pessimistic" />
  </TopToolbar>
)

const SessionShow = (props: ShowProps) => {
  const translate = useTranslate()
  return (
    <Show {...props} actions={<SessionShowActions />}>
      <SimpleShowLayout>
        <NumberField source="id" />
        <Grid container>
          <Grid item xs={6} sm={2}>
            <FormControl style={{ marginBottom: 8, marginRight: 16 }}>
              <FormHelperText>{translate('resources.session.fields.deviceStartDate')}</FormHelperText>
              <ShortDateTimeField source="deviceStartDate" />
            </FormControl>
          </Grid>
          <Grid item xs={6} sm={2}>
            <FormControl style={{ marginBottom: 8, marginRight: 16 }}>
              <FormHelperText>{translate('resources.session.fields.deviceEndDate')}</FormHelperText>
              <ShortDateTimeField source="deviceEndDate" />
            </FormControl>
          </Grid>
          <Grid item xs={6} sm={2}>
            <FormControl>
              <FormHelperText>{translate('resources.session.fields.updateDate')}</FormHelperText>
              <ShortDateTimeField source="updateDate" />
            </FormControl>
          </Grid>
        </Grid>
        <FormControl style={{ marginBottom: 4 }}>
          <FormHelperText>{translate('resources.session.fields.endedBy')}</FormHelperText>
          <SessionEndedByField source="endedBy" />
        </FormControl>
        <FormControl style={{ marginBottom: 4 }}>
          <FormHelperText>{translate('resources.session.fields.challenge.name')}</FormHelperText>
          <SessionLinkChallengeField source="challenge" />
        </FormControl>
        <FormControl style={{ marginBottom: 4 }}>
          <FormHelperText>{translate('resources.account.fields.pseudo')}</FormHelperText>
          <SessionLinkAccountField source="pseudo" />
        </FormControl>
        <FormControl style={{ marginBottom: 4 }}>
          <FormHelperText>{translate('resources.session.fields.appVersion')}</FormHelperText>
          <TextField source="appVersion" emptyText="(non renseigné)" />
        </FormControl>
        <Grid container>
          <Grid item xs={12} sm={2}>
            <FormControl style={{ marginBottom: 8, marginRight: 32 }}>
              <FormHelperText>{translate('resources.session.fields.device')}</FormHelperText>
              <ReferenceField source="device.id" reference="device" link="show">
                <FunctionField
                  render={(record: Device) => {
                    return record ? record.brand + ' ' + record.model : '(indéterminé)'
                  }}
                />
              </ReferenceField>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={10}>
            <FormControl style={{ marginBottom: 8, marginRight: 32 }}>
              <FormHelperText>{translate('resources.session.fields.cscDevice')}</FormHelperText>
              <ReferenceField source="cscDevice.id" reference="csc-device" link="show">
                <FunctionField
                  label="name"
                  render={(record: CscDevice) => {
                    return record.name ?? '(pas de nom)'
                  }}
                />
              </ReferenceField>
            </FormControl>
          </Grid>
          <Grid item xs={4} sm={2}>
            <FormControl style={{ marginBottom: 8, marginRight: 32 }}>
              <FormHelperText>{translate('resources.session.fields.duration')}</FormHelperText>
              <NumberField source="duration" />
            </FormControl>
          </Grid>
          <Grid item xs={4} sm={2}>
            <FormControl style={{ marginBottom: 8, marginRight: 32 }}>
              <FormHelperText>{translate('resources.session.fields.distance')}</FormHelperText>
              <NumberField source="distance" />
            </FormControl>
          </Grid>
          <Grid item xs={4} sm={2}>
            <FormControl style={{ marginBottom: 8 }}>
              <FormHelperText>{translate('resources.session.fields.calories')}</FormHelperText>
              <NumberField source="calories" />
            </FormControl>
          </Grid>
        </Grid>
        <FormControl style={{ marginBottom: 4 }}>
          <FormHelperText>Position</FormHelperText>
          <FunctionField
            render={(record: any) => {
              if (record.latitude && record.longitude) {
                return (
                  <a href={`https://www.google.fr/maps/@${record.latitude},${record.longitude},16z`} target="_blank" rel="noreferrer">
                    {record.latitude},{record.longitude}
                  </a>
                )
              } else {
                return <Typography>Indisponible pour cette session</Typography>
              }
            }}
          />
        </FormControl>
        <RpmGraph source="rpms" />
      </SimpleShowLayout>
    </Show>
  )
}

export default SessionShow
