import React from 'react'
import {
  Datagrid,
  FilterButton,
  FunctionField,
  List,
  type ListProps,
  Pagination,
  SelectInput,
  ShowButton,
  TextField,
  TopToolbar,
  useTranslate
} from 'react-admin'
import { Android } from '@mui/icons-material'
import { Chip } from '@mui/material'

import { ShortDateTimeField } from '../components/ShortDateTimeField'
import { NotificationCategoryChip } from '../components/NotificationFields'
import { NotificationCategory } from '../types'

const NotificationFilters = () => {
  const translate = useTranslate()
  return [
    <SelectInput
      alwaysOn
      source="topic"
      choices={[
        { id: NotificationCategory.CHALLENGE_NEW, name: translate('resources.notification.topic.challengeNew') },
        { id: NotificationCategory.CHALLENGE_END_SOON, name: translate('resources.notification.topic.challengeEndSoon') },
        { id: NotificationCategory.FROM_QUEENY, name: translate('resources.notification.topic.fromQueeny') },
        { id: NotificationCategory.FROM_PARTNERS, name: translate('resources.notification.topic.fromPartners') },
        { id: NotificationCategory.CHALLENGE_REWARD_WON, name: translate('resources.notification.topic.challengeRewardWon') }
      ]}
    />,
    <SelectInput
      alwaysOn
      label={translate('resources.notification.filters.type')}
      source="type"
      choices={[
        { id: 1, name: translate('resources.notification.filters.auto') },
        { id: 2, name: translate('resources.notification.filters.manual') }
      ]}
    />
  ]
}

const NotificationListActions = (props: any) => (
  <TopToolbar>
    <FilterButton />
  </TopToolbar>
)

const NotificationList = (props: ListProps) => {
  const translate = useTranslate()
  return (
    <List
      {...props}
      filters={NotificationFilters()}
      perPage={20}
      pagination={<Pagination rowsPerPageOptions={[20, 50, 100]} />}
      actions={<NotificationListActions />}
      sort={{ field: 'id', order: 'DESC' }}>
      <Datagrid bulkActionButtons={false}>
        <TextField source="id" />
        <TextField source="title" />
        <ShortDateTimeField source="sentDate" />
        <NotificationCategoryChip source="topic" />
        <FunctionField
          label={translate('resources.notification.filters.type')}
          render={(record: any) => {
            if (record.createdBy.id === 1) {
              return (
                <Chip
                  label={translate('resources.notification.filters.auto')}
                  color="default"
                  onDelete={() => {}}
                  deleteIcon={<Android />}
                />
              )
            } else {
              return <Chip label={translate('resources.notification.filters.manual')} color="primary" />
            }
          }}
        />
        <ShowButton />
      </Datagrid>
    </List>
  )
}

export default NotificationList
