import { HttpError } from 'react-admin'
import type { AuthProvider } from 'react-admin'
import { jwtDecode, type JwtPayload } from 'jwt-decode'

import type { AppPermissions } from '../../types'
import Api from '../api'

/* psam 01/24, d'où sort ça ?
interface CheckRequest {
  statusCode: number
  message: string
  error: string
}
*/
interface AccountPayload extends JwtPayload {
  id: string
  fullName: string
  avatar: string
}

// https://github.com/marmelab/react-admin/blob/v4.16.6/docs/AuthProviderWriting.md
const authProvider: AuthProvider = {
  login: async ({ username, password }: { username: string; password: string }) => {
    return await Api.login(username, password)
  },
  logout: async (params: any) => {
    const refreshToken = localStorage.getItem('refreshToken')
    if (refreshToken) {
      localStorage.removeItem('token')
      localStorage.removeItem('refreshToken')
      localStorage.removeItem('permissions')
      await Api.logout(refreshToken)
    }
    return Promise.resolve()
  },
  checkAuth: (/*params: CheckRequest*/) => localStorage.getItem('token')
    ? Promise.resolve()
    : Promise.reject(/*{ redirectTo: '/login' }*/),
  checkError: ({ status }: { status: number }) => { // error est { message: string, status: number, body: Object }
    if (/*status === 401 ||*/ status === 403) {
      return Promise.reject()
    }
    return Promise.resolve()
  },
  getIdentity: () => {
    const token = localStorage.getItem('token')
    if (token) {
      const { id, fullName, avatar } = jwtDecode<AccountPayload>(token)
      return Promise.resolve({ id, fullName, avatar })
    }
    return Promise.reject()
  },
  getPermissions: async (params: any): Promise<AppPermissions> => { // params éventuellement fournis en usePermissions()
    const token = localStorage.getItem('token')
    let isExpired = false
    if (token) {
      const decoded = jwtDecode<JwtPayload>(token)
      if (decoded.exp) {
/* psam 01/24, inutile de comparer en local time, rester en timestamps utc
        const exp = utcToZonedTime(decoded.exp * 1000, 'Europe/Paris')
        const before = isBefore(new Date(), exp)
        if (!before) {
          isExpired = true
        }
 */
        isExpired = decoded.exp * 1000 < Date.now() // exp est en secondes, now en millisecondes
      } else {
        isExpired = true
      }
    }
    const p = localStorage.getItem('permissions')
    let permissions: any
    if (p && !isExpired) {
      permissions = JSON.parse(p)
    } else {
      permissions = await Api.get('resources').json()
      if (permissions.statusCode === 401) {
        throw new HttpError(permissions.message, permissions.statusCode)
      }
      localStorage.setItem('permissions', JSON.stringify(permissions))
    }
    return Promise.resolve({
      permissions,
      roles: ['reader'] // psam 01/24, semble ne jamais être utilisé (src\core\hooks.tsx)
    })
  }
}

export default authProvider
