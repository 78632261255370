import React, { useEffect, useState } from 'react'
import { AppBar as RAAppBar } from 'react-admin'
import { CircularProgress, IconButton, Tooltip, Typography } from '@mui/material'
import { NotificationsNone, NotificationsPaused } from '@mui/icons-material'

import Api from '../core/api'
import UserMenu from './UserMenu'

const AppBar = (props: any) => {
  const [isLive, setIsLive] = useState<boolean | null>(null)

  useEffect(() => {
    Api.get('resources/live').then(async (res) => {
      setIsLive(await res.json())
    })
  }, [isLive])
  return (
    <RAAppBar
      {...props}
      sx={{
        '& .RaAppBar-title': {
          flex: 1,
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          overflow: 'hidden'
        }
      }}
      userMenu={<UserMenu />}>
      <Typography variant="h6" color="inherit" id="react-admin-title" />
      <span style={{ flex: 1 }} />
      {isLive === null ? (
        <CircularProgress color="inherit" size={16} />
      ) : isLive ? (
        <Tooltip title="Mode Live : notifications et emails sont envoyés aux participants !">
          <IconButton aria-label="NotificationsNone" color="inherit">
            <NotificationsNone />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Mode silencieux : aucune notification ni email n'est envoyé aux participants">
          <IconButton aria-label="NotificationsPaused" color="inherit">
            <NotificationsPaused />
          </IconButton>
        </Tooltip>
      )}
    </RAAppBar>
  )
}
export default AppBar
