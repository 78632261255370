import React from 'react'
import { Link, useCreatePath, useGetOne, useRecordContext } from 'react-admin'
import type { Team } from '../types'

const TeamChallengeLinkField = (props: any) => {
  const team = useRecordContext<Team>(props)
  const createPath = useCreatePath()
  const { data, isLoading } = useGetOne('challenge', { id: team.challenge.id })
  if (data && !isLoading) {
    return <Link to={createPath({ resource: 'challenge', type: 'show', id: data.id })}>{data.name}</Link>
  } else {
    return null
  }
}

export { TeamChallengeLinkField }
