import React from 'react'
import { Title } from 'react-admin'
import { Box, Grid, Paper, Typography } from '@mui/material'
import {
  StatPartition,
  StatProblematic,
  StatEndedBy,
  StatTopChallenges,
  StatNewUsers,
  StatDuration,
  StatScatter
} from './components/StatFields'

const Dashboard = () => {
  return (
    <Grid container>
      <Grid item xs={12}>
        <Box m={2}>
          <Paper>
            <Box p={2}>
              <Title title="Tableau de bord" />
              <Typography variant="h5">Tableau de bord de Queeny : statistiques</Typography>
            </Box>
          </Paper>
        </Box>
      </Grid>
      <Grid item xs={12} sm={6} md={4} pb={2}>
        <StatPartition />
      </Grid>
      <Grid item xs={12} sm={6} md={4} pb={2}>
        <StatProblematic />
      </Grid>
      <Grid item xs={12} sm={6} md={4} pb={2}>
        <StatEndedBy />
      </Grid>
      <Grid item xs={12} sm={6} md={4} pb={2}>
        <StatTopChallenges />
      </Grid>
      <Grid item xs={12} sm={6} md={4} pb={2}>
        <StatNewUsers />
      </Grid>
      <Grid item xs={12} sm={6} md={4} pb={2}>
        <StatDuration />
      </Grid>
      <Grid item xs={12} sm={6} md={4} pb={2}>
        <StatScatter />
      </Grid>
    </Grid>
  )
}

export default Dashboard
