import { useEffect, useState } from 'react'
import { jwtDecode } from 'jwt-decode'

import { Role } from '../types'
import type { AccountPayload } from './api'

export interface UseProfileType {
  isAdmin: () => boolean
}

// Default values
export const initialProfileContext = {} as UseProfileType

const useProfile = (): UseProfileType => {
  const [hydrated, setHydrated] = useState<boolean>(false)
  const [profile, setProfile] = useState<AccountPayload | null>(null)

  const init = async () => {
    try {
      const token = localStorage.getItem('token')
      const refreshToken = localStorage.getItem('refreshToken')
      if (token && refreshToken) {
        const decoded = jwtDecode<AccountPayload>(token)
        if (decoded) {
          setProfile(decoded)
        }
      } else {
      }
    } catch (error) {}
    setHydrated(true)
  }

  useEffect(() => {
    init()
  }, [])

  const isAdmin = (): boolean => {
    if (hydrated && profile) {
      return profile.role === Role.Admin
    }
    return false
  }

  return { isAdmin }
}

export default useProfile
