import { useCallback } from 'react'
import {
  Create, type CreateProps, SimpleForm, TextInput,
  email, required,
  useNotify, useCreate
} from 'react-admin'
import { useNavigate } from 'react-router-dom'

const PartnerCreate = (props: CreateProps) => {
  const notify = useNotify()
  const navigate = useNavigate()
  const [create] = useCreate()

  const onSubmit = useCallback(
    async (data: any) => {
      try {
        await create('partner', { data }, { returnPromise: true })
        notify('resources.partner.helpers.created', { type: 'success' })
        navigate('/partner')
      } catch (error: any) {
        console.log(error)
        notify(`resources.errors.${error.message}`, { type: 'warning' })
      }
    },
    [navigate, notify, create]
  )

  return (
    <Create redirect="list">
      <SimpleForm onSubmit={onSubmit}>
        <TextInput fullWidth source="email" label="resources.account.fields.email" validate={[required(), email()]} type="email" />
        <TextInput fullWidth source="pseudo" label="resources.account.fields.pseudo" validate={[required()]} />
        <TextInput fullWidth source="lastName" label="resources.account.fields.lastName" />
        <TextInput fullWidth source="firstName" label="resources.account.fields.firstName" />
      </SimpleForm>
    </Create>
  )
}

export default PartnerCreate
