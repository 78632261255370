import React, { useEffect, useState } from 'react'
import { useNotify } from 'react-admin'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { Avatar, Button, TextField } from '@mui/material'
import { Lock } from '@mui/icons-material'

import AuthLayout from '../customAdminUi/AuthLayout'

const ForgotPassword = (props: any) => {
  const notify = useNotify()
  const navigate = useNavigate()
  let [searchParams] = useSearchParams()

  const [email, setEmail] = useState<string>('')
  const [loading, setLoading] = useState<boolean>(false)

  useEffect(() => {
    let c = searchParams.get('redirect')
    if (c) {
      setEmail(c)
    }
  }, [searchParams])

  const submit = (e: any) => {
    e.preventDefault()
    setLoading(true)
    fetch(`${process.env.REACT_APP_API_ENDPOINT}/auth/request-new-password/${email}`, { method: 'GET' })
      .then(() => {
        notify('resources.auth.form.requestnew', { type: 'success' })
        setEmail('')
      })
      .catch((e) => {
        notify('resources.auth.form.error', { type: 'warning' })
      })
      .finally(() => {
        setLoading(false)
      })
  }

  return (
    <AuthLayout>
      <form onSubmit={submit} style={{ display: 'flex', flexDirection: 'column', minWidth: 300 }}>
        <Avatar style={{ alignSelf: 'center', marginBottom: 16 }}>
          <Lock />
        </Avatar>
        <TextField
          style={{ marginBottom: 16 }}
          autoFocus
          name="email"
          type="email"
          value={email}
          label="Email"
          error={email === ''}
          helperText={email === '' ? 'Votre email est obligatoire' : ' '}
          required
          onChange={(e) => setEmail(e.target.value)}
        />

        <Button variant="contained" color="primary" type="submit" disabled={loading}>
          Demande de réinitialisation
        </Button>
        <div style={{ height: 30 }} />
        <Button color="primary" onClick={() => navigate('/login')}>
          Connexion
        </Button>
      </form>
    </AuthLayout>
  )
}

export default ForgotPassword
