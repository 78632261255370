import { useEffect, useState } from 'react'
import { useTranslate } from 'react-admin'
import { Box, FormControl, Paper, Select, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import {
  VictoryAxis,
  VictoryBar,
  VictoryChart,
  VictoryGroup,
  VictoryHistogram,
  VictoryLabel,
  VictoryLegend,
  VictoryLine,
  VictoryPie,
  VictoryScatter,
  VictoryTheme,
  VictoryZoomContainer
} from 'victory'
import { format } from 'date-fns'

import Api from '../core/api'

const colorScale = ['#00aeae', '#feb300', '#FFE900', '#ff4600', '#7308a5', '#cc00af', '#0000ff', '#ba00ff']

const useStyles = makeStyles(() => ({
  densePadding: {
    margin: '0 4px',
    '& .MuiNativeSelect-select.MuiOutlinedInput-input': { padding: '0 28px 0 4px' }
  }
}))

const StatPartition = ({ challenge }: { challenge?: string }) => {
  const classes = useStyles()
  const [period, setPeriod] = useState<any>(7)
  const [data, setData] = useState<any>(null)

  useEffect(() => {
    if (challenge) {
      Api.get(`stats/partition?challenge=${challenge}`).then(async (res: any) => {
        setData(await res.json())
      })
    } else {
      Api.get(`stats/partition?period=${period}`).then(async (res: any) => {
        setData(await res.json())
      })
    }
  }, [period, challenge])

  return (
    <Box m={2} style={{ height: '100%' }}>
      <Paper style={{ height: '100%' }}>
        <Box p={2}>
          <FormControl style={{ flexDirection: 'row', flexWrap: 'wrap' }}>
            {challenge ? (
              <Typography variant="subtitle1">Répartition des sessions</Typography>
            ) : (
              <>
                <Typography variant="subtitle1">Répartition des sessions sur les</Typography>
                <Select
                  native
                  className={classes.densePadding}
                  value={period}
                  onChange={(e) => setPeriod(e.target.value)}
                  inputProps={{
                    name: 'period',
                    id: 'period'
                  }}>
                  <option value={7}>7</option>
                  <option value={14}>14</option>
                  <option value={30}>30</option>
                  <option value={60}>60</option>
                  <option value={90}>90</option>
                </Select>
                <Typography variant="subtitle1">derniers jours</Typography>
              </>
            )}
          </FormControl>
          {data?.total === 0 ? (
            <Paper>
              <Typography align="center" style={{ padding: '2rem' }}>
                {challenge ? 'Pas de donnée' : `Pas de donnée sur les ${period} derniers jours`}
              </Typography>
            </Paper>
          ) : (
            <VictoryChart theme={VictoryTheme.material} domainPadding={10}>
              <VictoryLabel x={20} y={40} text="Nb de sessions" />
              <VictoryLabel x={310} y={300} text="Durée" />
              <VictoryAxis
                style={{
                  tickLabels: { fontSize: 9, padding: 0, angle: -45, textAnchor: 'end' }
                }}
              />
              <VictoryAxis dependentAxis />
              <VictoryBar
                data={data?.data.map(({ x, y }: any) => ({ x, y: Number(y) }))}
                events={[
                  {
                    target: 'data',
                    eventHandlers: {
                      onMouseOver: () => {
                        return [
                          {
                            target: 'data',
                            mutation: (props) => {
                              const fill = props.style && props.style.fill
                              return fill === '#2196f3' ? null : { style: { fill: '#2196f3' } }
                            }
                          }
                        ]
                      },
                      onMouseOut: () => {
                        return [
                          {
                            target: 'data',
                            mutation: () => {
                              return null
                            }
                          }
                        ]
                      }
                    }
                  }
                ]}
                labels={({ datum }) => {
                  return datum.y
                }}
              />
            </VictoryChart>
          )}
        </Box>
      </Paper>
    </Box>
  )
}

const StatProblematic = () => {
  const classes = useStyles()
  const [period, setPeriod] = useState<any>(7)
  const [data, setData] = useState<any>(null)
  useEffect(() => {
    Api.get(`stats/problematic-sessions?period=${period}`).then(async (res: any) => {
      setData(await res.json())
    })
  }, [period])

  return (
    <Box m={2} style={{ height: '100%' }}>
      <Paper style={{ height: '100%' }}>
        <Box p={2}>
          <FormControl style={{ flexDirection: 'row', flexWrap: 'wrap' }}>
            <Typography variant="subtitle1">Sessions "à problème" par durée sur les</Typography>
            <Select
              native
              className={classes.densePadding}
              value={period}
              onChange={(e) => setPeriod(e.target.value)}
              inputProps={{
                name: 'probl',
                id: 'probl'
              }}>
              <option value={7}>7</option>
              <option value={14}>14</option>
              <option value={30}>30</option>
              <option value={60}>60</option>
              <option value={90}>90</option>
            </Select>
            <Typography variant="subtitle1">derniers jours</Typography>
          </FormControl>
          {data?.total === 0 ? (
            <Paper>
              <Typography align="center" style={{ padding: '2rem' }}>
                Pas de données sur les {period} derniers jours
              </Typography>
            </Paper>
          ) : (
            <>
              <VictoryChart theme={VictoryTheme.material} domainPadding={15}>
                <VictoryLabel x={20} y={20} text="Nb de sessions" />
                <VictoryLabel x={310} y={300} text="Durée moyenne" />
                <VictoryAxis
                  style={{
                    tickLabels: { fontSize: 9, padding: 0, angle: -45, textAnchor: 'end' }
                  }}
                />
                <VictoryAxis dependentAxis />
                <VictoryGroup offset={6}>
                  <VictoryBar data={data?.data[0].items} style={{ data: { fill: colorScale[0] } }} />
                  <VictoryBar data={data?.data[1].items} style={{ data: { fill: colorScale[1] } }} />
                  <VictoryBar data={data?.data[2].items} style={{ data: { fill: colorScale[2] } }} />
                  <VictoryBar data={data?.data[3].items} style={{ data: { fill: colorScale[3] } }} />
                </VictoryGroup>
              </VictoryChart>
              <VictoryLegend
                width={450}
                height={70}
                gutter={30}
                orientation="horizontal"
                itemsPerRow={2}
                style={{ border: { stroke: 'grey' } }}
                data={[
                  { name: 'Bluetooth perdu', symbol: { fill: colorScale[0], type: 'square' } },
                  { name: 'Déconnecté', symbol: { fill: colorScale[1], type: 'square' } },
                  { name: 'Utilisateur inactif', symbol: { fill: colorScale[2], type: 'square' } },
                  { name: 'Session suspecte !', symbol: { fill: colorScale[3], type: 'square' } }
                ]}
              />
            </>
          )}
        </Box>
      </Paper>
    </Box>
  )
}

const StatEndedBy = () => {
  const classes = useStyles()
  const translate = useTranslate()
  const [period, setPeriod] = useState<any>(7)
  const [data, setData] = useState<any>(null)
  useEffect(() => {
    Api.get(`stats/ended-by?period=${period}`).then(async (res: any) => {
      setData(await res.json())
    })
  }, [period])

  return (
    <Box m={2} style={{ height: '100%' }}>
      <Paper style={{ height: '100%' }}>
        <Box p={2}>
          <FormControl style={{ flexDirection: 'row', flexWrap: 'wrap' }}>
            <Typography variant="subtitle1">Type de fin de session sur les</Typography>
            <Select
              native
              className={classes.densePadding}
              value={period}
              onChange={(e) => setPeriod(e.target.value)}
              inputProps={{
                name: 'ended',
                id: 'ended'
              }}>
              <option value={7}>7</option>
              <option value={14}>14</option>
              <option value={30}>30</option>
              <option value={60}>60</option>
              <option value={90}>90</option>
            </Select>
            <Typography variant="subtitle1">derniers jours</Typography>
          </FormControl>
          {data?.data.length === 0 ? (
            <Paper>
              <Typography align="center" style={{ padding: '2rem' }}>
                Pas de données sur les {period} derniers jours
              </Typography>
            </Paper>
          ) : (
            <>
              <VictoryPie
                height={380}
                colorScale={colorScale}
                data={data?.data.map(({ x, y }: any) => ({ x, y: Number(y) }))}
                labels={({ datum }) => datum.y}
              />
              <VictoryLegend
                height={95}
                gutter={30}
                orientation="horizontal"
                itemsPerRow={3}
                colorScale={colorScale}
                style={{ border: { stroke: 'grey' } }}
                data={data?.data.map(({ x }: any) => ({ name: translate(`resources.session.endedBy.${x}`) }))}
              />
            </>
          )}
        </Box>
      </Paper>
    </Box>
  )
}

const StatTopChallenges = () => {
  const classes = useStyles()
  const [topCriteria, setTopCriteria] = useState<'sessions' | 'distance'>('sessions')
  const [periodTop, setPeriodTop] = useState<any>(7)
  const [dataTop, setDataTop] = useState<any>(null)

  useEffect(() => {
    Api.get(`stats/top-challenges?period=${periodTop}&criteria=${topCriteria}`).then(async (res: any) => {
      setDataTop(await res.json())
    })
  }, [topCriteria, periodTop])

  return (
    <Box m={2} style={{ height: '100%' }}>
      <Paper style={{ height: '100%' }}>
        <Box p={2}>
          <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
            <FormControl>
              <Select
                native
                className={classes.densePadding}
                value={topCriteria}
                onChange={(e) => setTopCriteria(e.target.value as any)}
                inputProps={{
                  name: 'top',
                  id: 'top'
                }}>
                <option value="sessions">Sessions</option>
                <option value="distance">Distance (km)</option>
              </Select>
            </FormControl>
            <Typography variant="subtitle1">par Challenge sur les</Typography>
            <FormControl>
              <Select
                native
                className={classes.densePadding}
                value={periodTop}
                onChange={(e) => setPeriodTop(e.target.value)}
                inputProps={{
                  name: 'period-top',
                  id: 'period-top'
                }}>
                <option value={7}>7</option>
                <option value={14}>14</option>
                <option value={30}>30</option>
                <option value={60}>60</option>
                <option value={90}>90</option>
              </Select>
            </FormControl>
            <Typography variant="subtitle1">derniers jours</Typography>
          </div>
          {dataTop?.data.length === 0 ? (
            <Paper>
              <Typography align="center" style={{ padding: '2rem' }}>
                Pas de données sur les {periodTop} derniers jours
              </Typography>
            </Paper>
          ) : (
            <VictoryChart theme={VictoryTheme.material} domainPadding={20} padding={{ top: 10, left: 20, bottom: 50, right: 15 }}>
              <VictoryAxis
                label="Challenges"
                tickLabelComponent={<VictoryLabel dy={-14} dx={8} />}
                style={{
                  tickLabels: { fontSize: 9, textAnchor: 'start', padding: 0 }
                }}
              />
              <VictoryAxis
                dependentAxis
                label={topCriteria === 'sessions' ? 'Nb de sessions' : 'Km'}
                axisLabelComponent={<VictoryLabel dy={20} />}
              />
              <VictoryBar
                horizontal
                data={dataTop?.data}
                barWidth={16}
                labels={({ datum }) => datum.y}
                events={[
                  {
                    target: 'data',
                    eventHandlers: {
                      onMouseOver: () => {
                        return [
                          {
                            target: 'data',
                            mutation: (props) => {
                              const fill = props.style && props.style.fill
                              return fill === '#2196f3' ? null : { style: { fill: '#2196f3' } }
                            }
                          }
                        ]
                      },
                      onMouseOut: () => {
                        return [
                          {
                            target: 'data',
                            mutation: () => {
                              return null
                            }
                          }
                        ]
                      }
                    }
                  }
                ]}
              />
            </VictoryChart>
          )}
        </Box>
      </Paper>
    </Box>
  )
}

const StatNewUsers = () => {
  const classes = useStyles()
  const [period, setPeriod] = useState<any>(7)
  const [data, setData] = useState<any>(null)
  useEffect(() => {
    Api.get(`stats/new-users?period=${period}`).then(async (res: any) => {
      const d = await res.json()
      let data = d.data.map((item: any) => ({ x: new Date(item.x), label: new Date(item.x) }))
      let bins = d.bins.map((item: any) => new Date(item))
      setData({ data, bins })
    })
  }, [period])

  return (
    <Box m={2} style={{ height: '100%' }}>
      <Paper style={{ height: '100%' }}>
        <Box p={2}>
          <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
            <Typography variant="subtitle1">Nouvelles inscriptions sur les</Typography>
            <FormControl>
              <Select
                native
                className={classes.densePadding}
                value={period}
                onChange={(e) => setPeriod(e.target.value)}
                inputProps={{
                  name: 'period-new-users',
                  id: 'period-new-users'
                }}>
                <option value={7}>7</option>
                <option value={14}>14</option>
                <option value={30}>30</option>
                <option value={60}>60</option>
                <option value={90}>90</option>
              </Select>
            </FormControl>
            <Typography variant="subtitle1">derniers jours</Typography>
          </div>
          {data?.data.length === 0 ? (
            <Paper>
              <Typography align="center" style={{ padding: '2rem' }}>
                Pas de données sur les {period} derniers jours
              </Typography>
            </Paper>
          ) : (
            <VictoryChart theme={VictoryTheme.material} domainPadding={1} padding={{ bottom: 60, left: 40, right: 0, top: 10 }}>
              <VictoryAxis
                tickFormat={(t: any) => format(t, 'dd/MM/yy')}
                style={{
                  tickLabels: { fontSize: 9, padding: 18, angle: -60, textAnchor: 'end' }
                }}
                label={'Nouvelles inscriptions par jour'}
              />
              <VictoryAxis dependentAxis />
              <VictoryHistogram
                data={data?.data}
                bins={data?.bins}
                events={[
                  {
                    target: 'data',
                    eventHandlers: {
                      onMouseOver: () => {
                        return [
                          {
                            target: 'data',
                            mutation: (props) => {
                              const fill = props.style && props.style.fill
                              return fill === '#2196f3' ? null : { style: { fill: '#2196f3' } }
                            }
                          }
                        ]
                      },
                      onMouseOut: () => {
                        return [
                          {
                            target: 'data',
                            mutation: () => {
                              return null
                            }
                          }
                        ]
                      }
                    }
                  }
                ]}
              />
            </VictoryChart>
          )}
        </Box>
      </Paper>
    </Box>
  )
}

const StatDuration = ({ challenge }: { challenge?: string }) => {
  const classes = useStyles()
  const [period, setPeriod] = useState<any>(7)
  const [data, setData] = useState<any>(null)
  useEffect(() => {
    if (challenge) {
      Api.get(`stats/duration?challenge=${challenge}`).then(async (res: any) => {
        const d = await res.json()
        if (d) {
          setData(d)
        }
      })
    } else {
      Api.get(`stats/duration?period=${period}`).then(async (res: any) => {
        setData(await res.json())
      })
    }
  }, [period, challenge])

  return (
    <Box m={2} style={{ height: '100%' }}>
      <Paper style={{ height: '100%' }}>
        <Box p={2}>
          <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
            {challenge ? (
              <Typography variant="subtitle1">Temps passé à pédaler (min)</Typography>
            ) : (
              <>
                <Typography variant="subtitle1">Temps passé à pédaler (min) sur les</Typography>
                <FormControl>
                  <Select
                    native
                    className={classes.densePadding}
                    value={period}
                    onChange={(e) => setPeriod(e.target.value)}
                    inputProps={{
                      name: 'period-new-users',
                      id: 'period-new-users'
                    }}>
                    <option value={7}>7</option>
                    <option value={14}>14</option>
                    <option value={30}>30</option>
                    <option value={60}>60</option>
                    <option value={90}>90</option>
                  </Select>
                </FormControl>
                <Typography variant="subtitle1">derniers jours</Typography>
              </>
            )}
          </div>
          {data?.data.length === 0 ? (
            <Paper>
              <Typography align="center" style={{ padding: '2rem' }}>
                {challenge ? 'Pas de donnée' : `Pas de donnée sur les ${period} derniers jours`}
              </Typography>
            </Paper>
          ) : (
            <VictoryChart
              theme={VictoryTheme.material}
              minDomain={{ y: 0 }}
              domainPadding={{ x: 10, y: 40 }}
              padding={{ bottom: 50, left: 40, right: 10, top: 20 }}
              containerComponent={<VictoryZoomContainer zoomDimension="x" />}>
              <VictoryAxis
                style={{
                  tickLabels: { fontSize: 9, padding: 4, angle: -60, textAnchor: 'end' }
                }}
              />
              <VictoryAxis dependentAxis />
              <VictoryLine
                interpolation="cardinal"
                data={data?.data}
                labels={({ datum }: {datum: {y: number}}) => Math.round(datum.y)}
                labelComponent={<VictoryLabel dy={-20} />}
              />
            </VictoryChart>
          )}
        </Box>
      </Paper>
    </Box>
  )
}

const StatScatter = ({ challenge }: { challenge?: string }) => {
  const classes = useStyles()
  const [period, setPeriod] = useState<any>(7)
  const [data, setData] = useState<any>(null)

  useEffect(() => {
    let url
    if (challenge) {
      url = `stats/scatter?challenge=${challenge}`
    } else {
      url = `stats/scatter?period=${period}`
    }
    Api.get(url).then(async (res: any) => {
      let data = await res.json()
      const days = ['Lun.', 'Mar.', 'Mer.', 'Jeu.', 'Ven.', 'Sam.', 'Dim.']
      const time = ['0h - 3h', '3h - 6h', '6h - 9h', '9h - 12h', '12h - 15h', '15h - 18h', '18h - 21h', '21h - 24h']
      if (data && data.data && data.data.length > 0) {
        data.data = data.data.map((d: any) => ({ x: days[d.x], y: time[d.y], total: d.total }))
        setData(data)
      }
    })
  }, [period, challenge])

  return (
    <Box m={2} style={{ height: '100%' }}>
      <Paper style={{ height: '100%' }}>
        <Box p={2}>
          <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
            {challenge ? (
              <Typography variant="subtitle1">Dispersion des sessions</Typography>
            ) : (
              <>
                <Typography variant="subtitle1">Dispersion des sessions sur les</Typography>
                <FormControl>
                  <Select
                    native
                    className={classes.densePadding}
                    value={period}
                    onChange={(e) => setPeriod(e.target.value)}
                    inputProps={{
                      name: 'period-scatter',
                      id: 'period-scatter'
                    }}>
                    <option value={7}>7</option>
                    <option value={14}>14</option>
                    <option value={30}>30</option>
                    <option value={60}>60</option>
                    <option value={90}>90</option>
                  </Select>
                </FormControl>
                <Typography variant="subtitle1">derniers jours</Typography>
              </>
            )}
          </div>
          {data?.data.length === 0 ? (
            <Paper>
              <Typography align="center" style={{ padding: '2rem' }}>
                {challenge ? 'Pas de donnée' : `Pas de données sur les ${period} derniers jours`}
              </Typography>
            </Paper>
          ) : (
            <VictoryChart
              theme={VictoryTheme.material}
              domainPadding={{ x: 22, y: 14 }}
              padding={{ left: 60, right: 10, top: 20, bottom: 40 }}>
              <VictoryAxis
                style={{
                  tickLabels: { fontSize: 10, padding: 4, angle: -45, textAnchor: 'end' }
                }}
              />
              <VictoryAxis
                dependentAxis
                style={{
                  tickLabels: { fontSize: 10 }
                }}
              />
              <VictoryScatter
                style={{
                  data: {
                    fill: ({ datum }) => {
                      if (datum.total < 1) {
                        return 'rgba(0,0,0,0)'
                      } else if (datum.total < 5) {
                        return '#ecda7f'
                      } else if (datum.total < 10) {
                        return '#f0c36f'
                      } else if (datum.total < 25) {
                        return '#e5a262'
                      } else if (datum.total < 50) {
                        return '#cd7a54'
                      } else if (datum.total < 75) {
                        return '#aa4a46'
                      } else {
                        return '#7e1435'
                      }
                    }
                  }
                }}
                maxBubbleSize={20}
                bubbleProperty="total"
                data={data?.data}
              />
            </VictoryChart>
          )}
        </Box>
      </Paper>
    </Box>
  )
}

const StatDeviceOSType = () => {
  const classes = useStyles()
  const [period, setPeriod] = useState<any>(7)
  const [data, setData] = useState<any>(null)
  useEffect(() => {
    Api.get(`stats/device/ostype?period=${period}`).then(async (res: any) => {
      setData(await res.json())
    })
  }, [period])

  return (
    <Box m={2} style={{ height: '100%' }}>
      <Paper style={{ height: '100%' }}>
        <Box p={2}>
          <FormControl style={{ flexDirection: 'row', flexWrap: 'wrap' }}>
            <Typography variant="subtitle1">Type de Smartphone avec au moins 1 session sur les</Typography>
            <Select
              native
              className={classes.densePadding}
              value={period}
              onChange={(e) => setPeriod(e.target.value)}
              inputProps={{
                name: 'ended',
                id: 'ended'
              }}>
              <option value={7}>7</option>
              <option value={14}>14</option>
              <option value={30}>30</option>
              <option value={60}>60</option>
              <option value={90}>90</option>
            </Select>
            <Typography variant="subtitle1">derniers jours</Typography>
          </FormControl>
          {data?.data.length === 0 ? (
            <Paper>
              <Typography align="center" style={{ padding: '2rem' }}>
                Pas de données sur les {period} derniers jours
              </Typography>
            </Paper>
          ) : (
            <>
              <VictoryPie
                height={380}
                colorScale={colorScale}
                data={data?.data.map(({ x, y }: any) => ({ x: x ?? 0, y: Number(y) }))}
                labels={({ datum }) => `${Math.round((datum.y / data?.total) * 100)} %`}
              />
              <VictoryLegend
                height={95}
                gutter={30}
                orientation="horizontal"
                itemsPerRow={3}
                colorScale={colorScale}
                style={{ border: { stroke: 'grey' } }}
                data={data?.data.map(({ x }: any) => ({ name: x === 'ios' ? 'Apple' : x === 'android' ? 'Android' : '(inconnu)' }))}
              />
            </>
          )}
        </Box>
      </Paper>
    </Box>
  )
}

const StatDeviceOSVersion = ({ os }: { os: 'Android' | 'iOS' }) => {
  const classes = useStyles()
  const [period, setPeriod] = useState<any>(7)
  const [data, setData] = useState<any>(null)
  useEffect(() => {
    Api.get(`stats/device/osversion?os=${os}&period=${period}`).then(async (res: any) => {
      setData(await res.json())
    })
  }, [period, os])

  return (
    <Box m={2} style={{ height: '100%' }}>
      <Paper style={{ height: '100%' }}>
        <Box p={2}>
          <FormControl style={{ flexDirection: 'row', flexWrap: 'wrap' }}>
            <Typography variant="subtitle1">Versions d'{os} avec au moins 1 session sur les</Typography>
            <Select
              native
              className={classes.densePadding}
              value={period}
              onChange={(e) => setPeriod(e.target.value)}
              inputProps={{
                name: 'ended',
                id: 'ended'
              }}>
              <option value={7}>7</option>
              <option value={14}>14</option>
              <option value={30}>30</option>
              <option value={60}>60</option>
              <option value={90}>90</option>
            </Select>
            <Typography variant="subtitle1">derniers jours</Typography>
          </FormControl>
          {data?.data.length === 0 ? (
            <Paper>
              <Typography align="center" style={{ padding: '2rem' }}>
                Pas de données sur les {period} derniers jours
              </Typography>
            </Paper>
          ) : (
            <>
              <VictoryPie
                height={380}
                colorScale={colorScale}
                data={data?.data.map(({ x, y }: any) => ({ x: x ?? 0, y: Number(y) }))}
                labels={({ datum }) => `${Math.round((datum.y / data?.total) * 100)} %`}
              />
              <VictoryLegend
                height={95}
                gutter={30}
                orientation="horizontal"
                itemsPerRow={3}
                colorScale={colorScale}
                style={{ border: { stroke: 'grey' } }}
                data={data?.data.map(({ x }: any) => ({ name: `${os} ${x}` }))}
              />
            </>
          )}
        </Box>
      </Paper>
    </Box>
  )
}

export {
  StatDuration,
  StatEndedBy,
  StatNewUsers,
  StatPartition,
  StatProblematic,
  StatScatter,
  StatTopChallenges,
  StatDeviceOSType,
  StatDeviceOSVersion
}
