import { Fragment, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Title, TopToolbar, Button } from 'react-admin'
import { format } from 'date-fns'
import { Box, Grid, Paper, Typography } from '@mui/material'
import { ChevronLeft } from '@mui/icons-material'
import { VictoryAxis, VictoryChart, VictoryHistogram, VictoryTheme } from 'victory'

import { StatDuration, StatPartition, StatScatter } from '../components/StatFields'
import Api from '../core/api'

const ChallengeStats = (props: any) => {
  const { id } = useParams()
  const navigate = useNavigate()

  ///
  const [dataSessionCount, setDataSessionCount] = useState<any>(null)
  useEffect(() => {
    if (!dataSessionCount) {
      Api.get(`stats/challenge/${id}/partition`).then(async (res: any) => {
        const d = await res.json()
        let data = d.data.map((item: any) => ({ x: new Date(item.x), label: new Date(item.x) }))
        let bins = d.bins.map((item: any) => new Date(item))
        setDataSessionCount({ data, bins })
      })
    }
  }, [id, dataSessionCount])

  return (
    <Fragment>
      <Title title={`Challenge n°${id} : statistiques `} />
      <TopToolbar>
        <Button color="primary" startIcon={<ChevronLeft />} onClick={() => navigate(`/challenge/${id}/show`)} label="Retour au Challenge" />
        <div style={{ width: 1, flex: 1 }} />
      </TopToolbar>
      <Grid container>
        <Grid item xs={12} sm={6} md={4} pb={2}>
          <Box m={2} style={{ height: '100%' }}>
            <Paper style={{ height: '100%' }}>
              <Box p={2}>
                {dataSessionCount?.data.length === 0 ? (
                  <Typography align="center" style={{ padding: '2rem' }}>
                    Pas de données sur ce Challenge
                  </Typography>
                ) : (
                  <VictoryChart theme={VictoryTheme.material} domainPadding={1} padding={{ bottom: 60, left: 30, right: 10, top: 10 }}>
                    <VictoryAxis
                      tickFormat={(t: any) => format(t, 'dd/MM/yy')}
                      style={{
                        tickLabels: { fontSize: 9, padding: 18, angle: -60, textAnchor: 'end' }
                      }}
                      label={'Répartition des sessions par jour'}
                    />
                    <VictoryAxis dependentAxis />
                    <VictoryHistogram
                      data={dataSessionCount?.data}
                      bins={dataSessionCount?.bins}
                      events={[
                        {
                          target: 'data',
                          eventHandlers: {
                            onMouseOver: () => {
                              return [
                                {
                                  target: 'data',
                                  mutation: (props) => {
                                    const fill = props.style && props.style.fill
                                    return fill === '#2196f3' ? null : { style: { fill: '#2196f3' } }
                                  }
                                }
                              ]
                            },
                            onMouseOut: () => {
                              return [
                                {
                                  target: 'data',
                                  mutation: () => {
                                    return null
                                  }
                                }
                              ]
                            }
                          }
                        }
                      ]}
                    />
                  </VictoryChart>
                )}
              </Box>
            </Paper>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={4} pb={2}>
          <StatPartition challenge={id} />
        </Grid>
        <Grid item xs={12} sm={6} md={4} pb={2}>
          <StatDuration challenge={id} />
        </Grid>
        <Grid item xs={12} sm={6} md={4} pb={2}>
          <StatScatter challenge={id} />
        </Grid>
      </Grid>
    </Fragment>
  )
}

export default ChallengeStats
