import React from 'react'
import {
  BooleanField,
  Labeled,
  NumberField,
  ReferenceField,
  Show,
  type ShowProps,
  SimpleShowLayout,
  TextField
} from 'react-admin'
import { Grid } from '@mui/material'

import { ShortDateTimeField } from '../components/ShortDateTimeField'
import { NotificationCategoryField, NotificationRecipientField } from '../components/NotificationFields'

const NotificationShow = (props: ShowProps) => {
  return (
    <Show {...props}>
      <SimpleShowLayout>
        <Grid container>
          <Grid item xs={1}>
            <Labeled>
              <NumberField source="id" />
            </Labeled>
          </Grid>
          <Grid item xs={11}>
            <Labeled>
              <TextField source="title" />
            </Labeled>
          </Grid>
          <Grid item xs={12}>
            <Labeled>
              <TextField source="body" />
            </Labeled>
          </Grid>
          <Grid item xs={6} sm={1}>
            <Labeled>
              <BooleanField source="immediate" />
            </Labeled>
          </Grid>
          <Grid item xs={6} sm={11}>
            <Labeled>
              <ShortDateTimeField source="sentDate" />
            </Labeled>
          </Grid>
          <Grid item xs={12}>
            <Labeled>
              <NotificationRecipientField source="recipient" />
            </Labeled>
          </Grid>
          <Grid item xs={12}>
            <Labeled>
              <NotificationCategoryField source="topic" />
            </Labeled>
          </Grid>
          <Grid item xs={12}>
            <Labeled>
              <TextField source="logs" />
            </Labeled>
          </Grid>
          <Grid item xs={6} sm={2}>
            <Labeled>
              <ShortDateTimeField source="creationDate" />
            </Labeled>
          </Grid>
          <Grid item xs={6} sm={10}>
            <Labeled>
              <ShortDateTimeField source="updateDate" />
            </Labeled>
          </Grid>
          <Grid item xs={12}>
            <Labeled>
              <ReferenceField source="createdBy.id" reference="account" link="show">
                <TextField source="pseudo" />
              </ReferenceField>
            </Labeled>
          </Grid>
        </Grid>
      </SimpleShowLayout>
    </Show>
  )
}

export default NotificationShow
