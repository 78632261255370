import React from 'react'
import {
  Datagrid,
  FilterButton,
  Link,
  List,
  type ListProps,
  NumberField,
  Pagination,
  SearchInput,
  SelectInput,
  TextField,
  TopToolbar,
  useCreatePath,
  useGetOne,
  useRecordContext
} from 'react-admin'
import { PrizeTeamLinkField, PrizeTeamStatusField } from '../components/PrizeFields'
import type { Prize } from '../types'

const PrizeImageField = (props: any) => {
  const record = useRecordContext<Prize>(props)
  if (record && record.image) {
    return <img src={record.image.filename} alt={record.image.filename} height="50" />
  } else {
    return null
  }
}

const PrizeChallengeLinkField = (props: any) => {
  const prize = useRecordContext<Prize>(props)
  const createPath = useCreatePath()
  const { data, isLoading } = useGetOne('challenge', { id: prize.challenge.id })
  if (data && !isLoading) {
    return <Link to={createPath({ resource: 'challenge', type: 'show', id: data.id })}>{`#${data.id} - ${data.name}`}</Link>
  } else {
    return null
  }
}

const prizeFilters = [
  <SearchInput fullWidth source="s" alwaysOn placeholder="Nom du Challenge" />,
  <SelectInput
    style={{ minWidth: 200 }}
    alwaysOn
    source="challenge.status"
    choices={[
      { id: 'ended', name: 'Oui' },
      { id: 'ongoing', name: 'Non' }
    ]}
  />
]

const PrizeListActions = (props: any) => (
  <TopToolbar>
    <FilterButton />
  </TopToolbar>
)

const PrizeList = (props: ListProps) => (
  <List
    {...props}
    filters={prizeFilters}
    perPage={20}
    pagination={<Pagination rowsPerPageOptions={[20, 50, 100]} />}
    actions={<PrizeListActions />}>
    <Datagrid bulkActionButtons={false}>
      <TextField source="id" />
      <PrizeChallengeLinkField source="challenge" />
      <NumberField source="prizeOrder" />
      <PrizeImageField source="image" />
      <PrizeTeamStatusField source="team.status" />
      <PrizeTeamLinkField source="team" />
    </Datagrid>
  </List>
)

export default PrizeList
