import React from 'react'
import {
  DatagridConfigurable,
  List,
  type ListProps,
  Pagination,
  SearchInput,
  SelectColumnsButton,
  SelectInput,
  ShowButton,
  TextField,
  TopToolbar,
  useRecordContext,
  useTranslate
} from 'react-admin'
import { AdminPanelSettings, MonetizationOn } from '@mui/icons-material'

import AvatarImageField from '../components/AvatarImageField'
import { ShortDateField } from '../components/ShortDateTimeField'
import { type Account, Role } from '../types'

const RoleField = (props: any) => {
  const account = useRecordContext<Account>(props)
  if (account.role === Role.Admin) {
    return (
      <span>
        <AdminPanelSettings aria-label="test" />
      </span>
    )
  } else if (account.role === Role.Advertiser) {
    return (
      <span>
        <MonetizationOn />
      </span>
    )
  } else {
    return <span />
  }
}

const AccountFilters = () => {
  const translate = useTranslate()
  return [
    <SearchInput fullWidth source="s" alwaysOn />,
    <SelectInput
      alwaysOn
      source="role"
      choices={[
        { id: 'user', name: translate('resources.account.filters.user') },
        { id: 'advertiser', name: translate('resources.account.filters.partner') },
        { id: 'admin', name: translate('resources.account.filters.admin') }
      ]}
    />
  ]
}

const AccountListActions = (props: any) => {
  return (
    <TopToolbar>
      <SelectColumnsButton />
    </TopToolbar>
  )
}

const AccountList = (props: ListProps) => {
  return (
    <List
      {...props}
      title={'Liste des Utilisateurs'}
      filters={AccountFilters()}
      perPage={10}
      pagination={<Pagination rowsPerPageOptions={[10, 20, 50, 100]} />}
      actions={<AccountListActions />}
      sort={{ field: 'id', order: 'DESC' }}>
      <DatagridConfigurable bulkActionButtons={false}>
        <TextField source="id" />
        <AvatarImageField source="image" maxHeight={100} sortable={false} />
        <TextField source="firstName" />
        <TextField source="lastName" />
        <TextField source="pseudo" />
        <TextField source="email" />
        <ShortDateField source="creationDate" />
        <ShortDateField source="updateDate" />
        <RoleField source="role" />
        <ShowButton label="Voir" />
      </DatagridConfigurable>
    </List>
  )
}

export default AccountList
