import React from 'react'
import {
  Button,
  Datagrid,
  FilterButton,
  Link,
  List,
  type ListProps,
  Pagination,
  SearchInput,
  SelectInput,
  ShowButton,
  TextField,
  TopToolbar,
  useCreatePath,
  useGetOne,
  useRecordContext
} from 'react-admin'
import { Chip } from '@mui/material'
import { Android, Apple, QueryStats } from '@mui/icons-material'

import type { Device } from '../types'
import { useNavigate } from 'react-router-dom'

const LinkAccountField = (props: any) => {
  const device = useRecordContext<Device>(props)
  const createPath = useCreatePath()
  const { data, isLoading } = useGetOne('account', { id: device.account?.id })
  if (data && !isLoading) {
    return <Link to={createPath({ resource: 'account', type: 'show', id: data.id })}>{data[props.source]}</Link>
  } else {
    return null
  }
}

export const OsField = (props: any) => {
  const record = useRecordContext<Device>(props)
  if (record.os === 'android') {
    return <Chip icon={<Android />} style={{ padding: 4, margin: 4 }} label={`Android ${record.osVersion}`} />
  } else {
    return <Chip icon={<Apple />} style={{ padding: 4, margin: 4 }} label={`iOS ${record.osVersion}`} />
  }
}

const deviceFilters = [
  <SearchInput fullWidth source="s" alwaysOn />,
  <SelectInput
    alwaysOn
    source="os"
    choices={[
      { id: 'android', name: 'Android' },
      { id: 'ios', name: 'iOS' }
    ]}
  />
]

const DeviceListActions = (props: any) => {
  const navigate = useNavigate()
  return (
    <TopToolbar>
      <FilterButton />
      <Button color="primary" onClick={() => navigate(`stats`)} label="resources.challenge.actions.stats">
        <QueryStats />
      </Button>
    </TopToolbar>
  )
}

const DeviceList = (props: ListProps) => (
  <List
    {...props}
    filters={deviceFilters}
    perPage={20}
    pagination={<Pagination rowsPerPageOptions={[20, 50, 100]} />}
    actions={<DeviceListActions />}
    sort={{ field: 'id', order: 'DESC' }}>
    <Datagrid bulkActionButtons={false}>
      <TextField source="id" />
      <TextField source="brand" />
      <TextField source="model" />
      <OsField source="os" sortable={false} />
      <LinkAccountField source="pseudo" sortable={false} />
      <LinkAccountField source="email" sortable={false} />
      <ShowButton label="Voir" />
    </Datagrid>
  </List>
)

export default DeviceList
