import React from 'react'
import {
  ArrayField,
  Datagrid,
  EditButton,
  NumberField,
  Pagination,
  ReferenceManyField,
  Show,
  ShowButton,
  type ShowProps,
  SimpleShowLayout,
  TextField,
  TopToolbar,
  useTranslate
} from 'react-admin'
import { FormControl, FormHelperText } from '@mui/material'

import { ShortDateField, ShortDateTimeField } from '../components/ShortDateTimeField'
import { SessionEndedByField, SessionLinkAccountField, SessionScoreField } from '../components/SessionFields'
import { TeamChallengeLinkField } from '../components/TeamFields'
import { CrudActions } from '../types'
import { useCanAccess } from '../core/hooks'

const TeamShowActions = (props: any) => {
  const { canAccess } = useCanAccess({ action: CrudActions.UpdateOne, resource: 'team' })
  return <TopToolbar>{canAccess && <EditButton />}</TopToolbar>
}

const TeamShow = (props: ShowProps) => {
  const translate = useTranslate()
  const { canAccess } = useCanAccess({ action: CrudActions.ReadAll, resource: 'account' })

  return (
    <Show actions={<TeamShowActions />}>
      <SimpleShowLayout>
        <NumberField source="id" />
        <FormControl style={{ display: 'flex', marginBottom: 4 }}>
          <FormHelperText>{translate('resources.team.fields.creationDate')}</FormHelperText>
          <ShortDateField source="creationDate" />
        </FormControl>
        <TextField source="name" />
        <FormControl style={{ display: 'flex', marginBottom: 4 }}>
          <FormHelperText>{translate('resources.team.fields.challenge.name')}</FormHelperText>
          <TeamChallengeLinkField source="challenge" />
        </FormControl>
        <ArrayField source="accounts" label="resources.team.helpers.accounts">
          <Datagrid bulkActionButtons={false}>
            <NumberField source="id" />
            <TextField source="firstName" />
            <TextField source="lastName" />
            <TextField source="pseudo" />
            <TextField source="email" />
            {canAccess && <ShowButton resource="account" />}
          </Datagrid>
        </ArrayField>
        <ReferenceManyField reference="session" target="team.id" label="resources.team.helpers.sessions" pagination={<Pagination />}>
          <Datagrid bulkActionButtons={false}>
            <ShortDateTimeField source="deviceStartDate" />
            <SessionEndedByField source="endedBy" />
            {canAccess && <SessionLinkAccountField source="pseudo" />}
            {canAccess && <SessionLinkAccountField source="email" />}
            <SessionScoreField source="score" />
            {<ShowButton resource="session" />}
          </Datagrid>
        </ReferenceManyField>
      </SimpleShowLayout>
    </Show>
  )
}

export default TeamShow
